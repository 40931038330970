import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "500px",
  },
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  flexInput: { margin: theme.spacing(1), flexGrow: "1" },  
  nestFlexInput: { margin: theme.spacing(4,0), flexGrow: "1" },
  nestTimeFlexInput: { flexGrow: "1" },
  accessGroupType: { flexBasis: "25%" },
  description: {
    marginTop: theme.spacing(1),
  },
  timeRadioGroup: {
    margin: theme.spacing(2),
    flexBasis: "100%",
    display: "flex",
  },
  radioButton: {
    margin: theme.spacing(1.5),
    transform: "scale(1.5)",
  },
  radioLabel: {
    fontSize: "medium",
  },
  checkboxLabel: {
    fontSize: "medium",
    margin: theme.spacing(1.5),
  },
  nestEnabledCheckboxLabel: {
    fontSize: "medium",
    display: "flex",
  },
  checkbox: {
    transform: "scale(1.5)",
    marginRight: theme.spacing(1),
  },
  attributesContainer: {
    width: "100%",
  },
  attributesFlexContainer: {
    display: "flex",
    marginLeft: theme.spacing(2),
  },
  nestSettingsFlexContainer: {
    display: "flex",
  },
  addAccessTimeBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  accessTimeContainer: {
    display: "inline-flex",
  },
  timesForms: {},
  accessReaderSection: {
    display: "block",
  },  
  inMinutes: {
    fontSize: "small",
    display: "flex",
    marginLeft: theme.spacing(2),
  },
}));
