import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStyles } from "./styles";
import clsx from "clsx";
import {
  Button,
  Grid,
  InputAdornment,
  RadioGroup,
  Radio,
  TextField,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import apiClient from "../../../auth/apiClient";
import ContractService from "../../../services/ContractService";
import { useSelector } from "react-redux";
import * as c from "../../../constants";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";

const contractService = new ContractService(apiClient);

const EditBalanceForm = ({
  toggleModal,
  currentBalance,
  setCurrentBalance,
  contractID,
  entityID,
  fundType,
  fundID,
  setFundID
}) => {
  const currentUserID = useSelector((state) => state.user.UserID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const facilityGroupId = useSelector((state) => state.entityScope?.facilityGroupId);
  const scopeAwareFacilityID = facilityGroupId || entityID;

  const classes = useStyles();
  const [updatedBalance, setUpdatedBalance] = useState(
    currentBalance?.toFixed(2)
  );

  const payment = "Payment";
  const charge = "Charge";
  const availableAdjustmentTypes = [payment, charge];

  const EditBalanceSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError("Must be a number")
      .min(0.01, "Enter as a positive number")
      .required("Required"),
    adjustmentType: Yup.string().oneOf(availableAdjustmentTypes),
    note: Yup.string()
      .max(200, "Must be less than 200 characters")
      .required("Required"),
  });

  const defaultValues = {
    amount: 0,
    adjustmentType: payment,
    note: "",
  };

  const methods = useForm({
    resolver: yupResolver(EditBalanceSchema),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  const handleTypeChange = (type) => {
    setValue("adjustmentType", type, { shouldValidate: true });
  };

  const watchedAmount = watch("amount");
  const watchedType = watch("adjustmentType");

  useEffect(() => {
    if (!isNaN(watchedAmount) && watchedAmount > 0) {
      let newBalance = 0;
      const adjustment = parseFloat(watchedAmount);

      if (watchedType === charge) {
        newBalance = currentBalance - adjustment;
      } else {
        newBalance = currentBalance + adjustment;
      }

      setUpdatedBalance(parseFloat(newBalance).toFixed(2));
    }
  }, [watchedAmount, watchedType]);

  const updateBalance = async (data) => {
    const { amount, note, adjustmentType } = data;

    const request = {
      amount,
      note,
      adjustmentType: c.FUND_ADJUSTMENT_TYPE[adjustmentType],
      userID: currentUserID,
      fundID,
      entityID: scopeAwareFacilityID,
      contractID,
    };
    
    let response;

    try {
      response = await contractService.updateFundBalance(
        scopeAwareFacilityID,
        contractID,
        request
      );

      if (!isNaN(response.data?.balance)) {
         setCurrentBalance(response.data.balance);
         setFundID(response.data.fundID);
      }

      toggleModal();
    } catch {
      enqueueSnackbar("Failed to update balance", {
        variant: "error",
        tag: "updateFundBalanceError",
      });
      return;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(async (data) => await updateBalance(data))}>
        <div className={clsx("form-content", classes.contentRoot)}>
          <Grid
            container
            spacing={2}
            className={clsx("amount-container", classes.amountContainer)}
          >
            <Grid item>
              <Controller
                name="amount"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label="Amount"
                    {...field}
                    error={!!errors.amount}
                    helperText={errors.amount && errors.amount.message}
                    type="text"
                    variant="outlined"
                    className={clsx("amount")}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 0.01,
                        "data-testid": "edit-balance-amount",
                      },
                      inputMode: "numeric",
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item>
              <RadioGroup
                aria-labelledby="edit-balance-type-radio-button-group"
                onChange={(e, type) => handleTypeChange(type)}
                name="radio-buttons-group"
                defaultValue={payment}
              >
                <FormControlLabel
                  value={payment}
                  control={<Radio />}
                  label={payment}
                />
                <FormControlLabel
                  value={charge}
                  control={<Radio />}
                  label={charge}
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid
            container
            className={clsx("comment-container", classes.commentContainer)}
          >
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  label="Comment"
                  {...field}
                  error={!!errors.comment}
                  helperText={errors.comment && errors.comment.message}
                  type="text"
                  variant="outlined"
                  className={clsx("amount")}
                  InputProps={{
                    inputProps: {
                      "data-testid": "edit-balance-comment",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid
            container
            className={clsx(
              "balance-update-container",
              classes.balanceAdjustmentContainer
            )}
          >
            <Typography
              data-testid="balance-update-text"
              variant="body1"
              className={clsx(
                "balance-update-text",
                watchedAmount !== 0 ? classes.showBalance : classes.hideBalance
              )}
            >
              New balance will be{" "}
              {updatedBalance < 0
                ? `-$${Math.abs(updatedBalance).toFixed(2)}`
                : `$${updatedBalance}`}
            </Typography>
          </Grid>

          <Grid
            container
            className={clsx("button-container", classes.buttonContainer)}
          >
            <Grid item>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                className={clsx("edit-balance-cancel-button")}
                disabled={isSubmitting || !isValid}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => toggleModal()}
                color="secondary"
                variant="contained"
                className={clsx(
                  "edit-balance-cancel-button",
                  classes.cancelButton
                )}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};

EditBalanceForm.propTypes = {
  toggleModal: PropTypes.func,
  currentBalance: PropTypes.number,
  setCurrentBalance: PropTypes.func,
  contractID: PropTypes.string,
  entityID: PropTypes.string,
  fundType: PropTypes.number,
  fundID: PropTypes.string,
  setFundID: PropTypes.func
};

export default EditBalanceForm;
