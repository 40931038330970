import clsx from "clsx";
import { isEmpty } from "lodash";
import PhoneField from "material-ui-phone-number";
import PropTypes from "prop-types";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import * as Yup from "yup";
import { useStyles } from "./style";
import { useCountry } from '../../../../hooks/useCountry';

export const PhoneSubformSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(["Unset", "Mobile", "Work", "Home"])
    .default("Mobile")
    .notRequired(),
  number: Yup.string()
    .test("length", "Phone number must consist of 10 digits", val => {
      if (isEmpty(val)) {
        return true;
      }
      return val?.replace(new RegExp(/[\W_]/g), "")?.length === 10;
    })
    .notRequired()
});

const PhoneSubform = ({ index, disabled }) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors }
  } = useFormContext();
  const { countries } = useCountry();

  return (
    <Controller
      name={`contactInfo.phoneNumbers.${index}.number`}
      control={control}
      render={({ field }) => (
        <PhoneField
          {...field}
          className={clsx("phone-number", classes.flexInput)}
          defaultCountry={"us"}
          disableAreaCodes
          disableCountryCode
          error={
            errors.contactInfo?.phoneNumbers &&
            errors.contactInfo?.phoneNumbers[index]?.number
              ? true
              : false
          }
          helperText={
            errors.contactInfo?.phoneNumbers &&
            errors.contactInfo?.phoneNumbers[index]?.number?.message
          }
          InputProps={{
            readOnly: Boolean(disabled),
            "aria-readonly": Boolean(disabled),
            disabled: Boolean(disabled)
          }}
          label="Phone Number"
          onlyCountries={countries.map((country) => country.alpha2.toLowerCase())}
          excludeCountries={['pm', 'bm']}
          regions={["north-america"]}
          size="small"
          variant="outlined"
        />
      )}
    />
  );
};

PhoneSubform.defaultProps = {
  disabled: false
};

PhoneSubform.propTypes = {
  index: PropTypes.number,
  disabled: PropTypes.bool
};

export default PhoneSubform;
