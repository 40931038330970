import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	root:{
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	details: {
		flexGrow: 1,
		display: "block"
	},
	heading: {
		fontSize: theme.typography.pxToRem(20),
		flexBasis: "33.33%",
		flexShrink: 0,
		color: theme.palette.primary.main,
	},
}));