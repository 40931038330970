import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./featureflagcard.style";
import clsx from "clsx";
import _ from "lodash";

import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import {
	Card,
	CardHeader,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemText,
	Switch,
	Grid,
	Typography,
	Button
} from "@material-ui/core";

import FeatureFlagStatusBar from "./featureflagstatusbar";
import FeatureFlagDrawerComponent from "../FeatureFlagDrawerComponents/featureflagdrawercomponent";
import { FlagFormContext } from "../flagformcontextprovider";

const FeatureFlagCard = ({ feature }) => {
	const classes = useStyles();
	const [drawerOpen, setDrawerOpen] = useState(false);

	const [isSaving, setIsSaving] = useState(false);

	const { currentState, updateState, submitChange, resetState } = useContext(FlagFormContext);

	const isFeatureEnabled = currentState?.map(f => f.features)?.flat()?.find(f => f.fullPath === feature.fullPath)?.enabled;

	const onSubmitClicked = () => {
		submitChange().then(result => {
			setDrawerOpen(false);
			setIsSaving(false);
		}, err => {
			resetState();
			setIsSaving(false);
		});
		setIsSaving(true);
	}

	const onClose = () => {
		if (!isSaving) {
			resetState();
			setDrawerOpen(false);
		}
	}

	return (
		<div className={classes.root}>
			<Card className={clsx(classes.card, "feature-card")} data-id={feature.fullPath} onClick={() => setDrawerOpen(true)} data-testid="feature-card">
				<FeatureFlagStatusBar status={isFeatureEnabled} />
				<CardHeader
					className={clsx(classes.cardHeader)}
					key={feature.fullPath}
					style={{ padding: 4, margin: 4 }}
					titleTypographyProps={{ variant: "h5" }}
					title={feature.name}
				/>
			</Card>

			<Drawer
				classes={{paper: classes.featureDrawer}}
				anchor="right"
				open={drawerOpen}
				onClose={() => onClose()}
			>
				<Typography
					color="primary"
					variant="h4"
					className={clsx(classes.header)}>Features</Typography>
				<span
					className={clsx(classes.headerDesc)}>
						Change the feature configuration for the <span className={clsx(classes.headerfName)}>{feature.name}</span> feature package.
				</span>
				<Divider/>
				<List className={classes.list}>
					<ListItem key={feature.name} className={clsx("feature-entry")} data-id={feature.name} >
						<ListItemText
							classes={{primary:classes.feature}}
							primary={feature.name}
							/>
						<Switch
							color="primary"
							edge="end"
							checked={!!feature.enabled}
							className={clsx("feature-switch")}
							data-id={feature.name}
							onChange={e => updateState(feature.fullPath, e.target.checked)}
						/>
					</ListItem>
					{feature.featureComponents.map(c => <FeatureFlagDrawerComponent key={c.fullPath} component={c} />)}
				</List>
				<Divider/>
				<Grid container className={classes.btnGrid}>
					<Button
						disabled={isSaving}
						className={clsx("feature-submit-btn")}
						onClick={() => onSubmitClicked()}
						data-testid="submitButton"
						type="submit"
						startIcon={<SaveIcon />}
						variant="contained"
						color="primary">
						{isSaving ? "Saving..." : "Save"}
					</Button>
					<Button
						className={clsx("feature-cancel-btn")}
						onClick={() => onClose()}
						startIcon={<CancelIcon />}
						variant="contained">
						Cancel
					</Button>
				</Grid>
			</Drawer>
		</div>

	);
};

FeatureFlagCard.defaultProps = { };
FeatureFlagCard.propTypes = { feature: PropTypes.object };

export default React.memo(FeatureFlagCard);
