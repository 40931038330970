import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";
import apiClient from "../../../auth/apiClient";
import CreditCardOnFileService from "../../../services/CreditCardOnFileService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import { CircularProgress } from "@material-ui/core";
import AppConfig from "AppConfig";
import { isUndefined } from "lodash";
import {PAYMENT_GATEWAY} from "../../../constants/index"

const creditCardOnFileService = new CreditCardOnFileService(apiClient);

const CardOnFileButton = ({ contractID, accessHolderID, showReplaceButton }) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tokenRequestResponse, setTokenRequestResponse] = React.useState({});
  const [hostBase, setHostBase] = useState('');
  const enqueueSnackbar = useEnqueueSnackbar();
  const currentLocation = window.location;
  const port = window.location.port ? ":" + window.location.port : "";

  useEffect(() => {
    //Auto submit form to redirect to Planet payment as soon as user clicks on ADD button if Payment gateway is Planet
    if(formRef.current && tokenRequestResponse? tokenRequestResponse.gatewaySettings.SelfSubmitFormURL: "") {
      formRef.current.submit();
    }
  }, [tokenRequestResponse.gateway === PAYMENT_GATEWAY.PLANET]);

  const showError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      tag: "cardOnFileButtonError",
    });
  };

  const isValidHttpUrl = (val) => {
    let url;
    try {
      url = new URL(val);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const fetchCreditCardToken = (contractID, hostBase, baseUrl) => {
    let response = creditCardOnFileService.requestCreditCardToken(
      contractID,
      hostBase,
      1.0,
      baseUrl
    );
    return response;
  };

  const getRedirectUri = async () => {
    try { 
      let hostBase = '';
      if (isUndefined(accessHolderID)) {
        hostBase = `${currentLocation.protocol}//${currentLocation.hostname}${port}/cardonfile/groupcontract/${contractID}`;
      } else {
        hostBase = `${currentLocation.protocol}//${currentLocation.hostname}${port}/cardonfile/accessholder/${contractID}/${accessHolderID}`;
      }
      setLoading(true);
      
      let tokenRequestResponse = await fetchCreditCardToken(contractID, hostBase, `${AppConfig.externalServices.baseURL}`)

      if(tokenRequestResponse.data?.gateway === PAYMENT_GATEWAY.WINDCAVE) {
        let redirectUri = tokenRequestResponse.data?.redirectUri;
        if (!isValidHttpUrl(redirectUri)) {
          showError(`Credit card was not processed. Reason: ${redirectUri}`);
          return;
        }

        window.location.replace(redirectUri);
      } else if(tokenRequestResponse.data?.gateway === PAYMENT_GATEWAY.PLANET) {
        setHostBase(`${hostBase}`+'?message=cancel');
        setTokenRequestResponse(tokenRequestResponse.data);
      }
    } catch (error) {
      showError("Unable to negotiate credit card on file processing");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <Button
      data-testid="add-replace-button"
      variant="contained"
      color="primary"
      className={clsx("add-replace-button")}
      onClick={getRedirectUri}
      disabled={loading}
    >
      <div>
        {showReplaceButton ? "Replace" : "Add"}
        {loading && (
          <CircularProgress
            color="secondary"
            size={12}
            style={{ marginLeft: "8px" }}
          />
        )}
      </div>
    </Button>
    {/* Adding form for redirection to planet payment page once user clicks on ADD button */}
    {tokenRequestResponse.gateway === PAYMENT_GATEWAY.PLANET &&  
      (
        <form ref={formRef} method="post" data-testid="autosubmit-form" action={tokenRequestResponse? tokenRequestResponse.gatewaySettings.SelfSubmitFormURL: ""}>
          <input type="hidden" name="XXX_IPGSESSION_XXX" value={tokenRequestResponse?.sessionID}/>
          <input type="hidden" name="merchant_script_data_1" value={hostBase}/>
          <input type="hidden" name="content_language" value="EN"/>
        </form>
    )}
  </>
  );
};

export default CardOnFileButton;
