import React, {useState} from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import clsx from "clsx";

const BooleanSelect = ({
    className,
    label,
    name,
    value,
    values,
    variant,
    onChange
}) => {
    const [selectedType, setSelectedType] = useState(value);

    const updateSelectedType = type => {
        setSelectedType(type);
    };

    return(
        <FormControl className={clsx(className)} size="small" variant={variant}>
            
            <Select
              name={name}
              value={selectedType?.value || value}
              variant="outlined"
              label={label}
              onChange={onChange}
              data-testid={name}
            >
                <MenuItem value={true}>{values[0]}</MenuItem>
                <MenuItem value={false}>{values[1]}</MenuItem>
            </Select>
        </FormControl>
    );
};

BooleanSelect.defaultProps = {
    label: "",
    values: ["false", "true"],
    variant: "outlined"
};

BooleanSelect.propTypes = {
    value: PropTypes.bool,
    values: PropTypes.arrayOf(PropTypes.any),
    variant: PropTypes.oneOf(["outlined", "standard", "filled"])
};

export default BooleanSelect;