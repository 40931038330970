import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Paper,
  Button,
} from "@material-ui/core";
import { useStyles } from "./index.styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Dropdown from "../../../Dropdowns/MultiSelect/index";
import ThirdpartyVendorService from "../../../../services/ThirdpartyVendorService";
import apiClient from "../../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { useFlags } from "launchdarkly-react-client-sdk";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/pro-duotone-svg-icons";

const thirdPartyVendorService = new ThirdpartyVendorService(apiClient);

const EnableThirdParties = ({
  entityID,
  subscribers,
  subscriberPermissions,
}) => {

  const classes = useStyles();

  const isAdmin = useSelector((state) => state?.user?.isAdmin);

  const [thirdPartyExpanded, setThirdPartyExpanded] = useState(true);
  const [selected, setSelected] = useState([]);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [entitySubscribers, setEntitySubscribers] = useState([]);
  const { showIntegratorPermissions } = useFlags();
  const [enabledOnly, setEnabledOnly] = useState(true);

  useEffect(() => {
    let entitySubsArray = [];
    if (subscribers?.length > 0) {
      subscribers.map((s) =>
        s.entitySubscribers?.map((es) => entitySubsArray.push(es))
      );
    }
    setEntitySubscribers(entitySubsArray);
    setSelectedDropdown(subscribers);
  }, [subscribers]);

  const setSelectedDropdown = (subscribers) => {
    let selectedArray = [];
    if (subscribers?.length > 0) {
      selectedArray = subscribers.filter((row) => {
        return row.entitySubscribers?.some(({ status }) => status == true);
      });
    }
    setSelected(
      selectedArray.map((row) => {
        return row.description;
      })
    );
  };

  const toggleSubscriberExpanded = () => {
    setThirdPartyExpanded(!thirdPartyExpanded);
  };

  const handleSelected = async (status) => {
    let arr1 = selected;
    let arr2 = status.target.value;

    let selectedItem = arr1
      .filter((val) => !arr2.includes(val))
      .concat(arr2.filter((val) => !arr1.includes(val)))
      .toString();

    let index = subscribers.map((e) => e.description).indexOf(selectedItem);
    if (index > -1) {
      handleStatusChange(index, subscribers[index].subscriberID);
      setSelected(arr2);
    }
  };

  const getEntitySubscriberStatus = (subscriberID) => {
    return entitySubscribers?.some(
      (x) => x.status && x.subscriberID === subscriberID
    );
  };
  const isSubscriberPermissionEnabled = (subscriberID, permissionID) => {
    return entitySubscribers?.some(
      (x) =>
        x.subscriberID === subscriberID && x.permissions?.includes(permissionID)
    );
  };

  const toggleEnabledOnly = (event) => {
    setEnabledOnly(!enabledOnly);
    event.stopPropagation();
    event.preventDefault();
  }

  const handleStatusChange = async (subscriberIndex, subscriberID) => {
    if (!isAdmin) {
      return;
    }

    let tempEntitySubs = [...entitySubscribers];
    let tempEntitySub;
    const index = tempEntitySubs.findIndex(
      (x) => x.subscriberID === subscriberID
    );
    if (index > -1) {
      tempEntitySub = tempEntitySubs[index];
      tempEntitySub.status = !tempEntitySub.status;
      tempEntitySub.permissions = [];
      setEntitySubscribers(tempEntitySubs);
    } else {
      tempEntitySub = {
        entityID: entityID,
        subscriberID: subscriberID,
        status: true,
        permissions: [],
      };
      setEntitySubscribers([...tempEntitySubs, tempEntitySub]);
    }
    handleChange(subscriberIndex, tempEntitySub);
  };

  const handlePermissionChange = async (
    subscriberIndex,
    subscriberID,
    permissionID
  ) => {
    let tempEntitySubs = [...entitySubscribers];
    let tempEntitySub;
    const index = tempEntitySubs.findIndex(
      (x) => x.subscriberID === subscriberID
    );
    if (index > -1) {
      tempEntitySub = tempEntitySubs[index];
      const permissionIndex = tempEntitySub.permissions?.findIndex(
        (x) => x === permissionID
      );
      permissionIndex > -1
        ? tempEntitySub.permissions.splice(permissionIndex, 1)
        : tempEntitySub.permissions.push(permissionID);
      tempEntitySub.status = tempEntitySub.permissions?.length > 0;
      setEntitySubscribers(tempEntitySubs);
    } else {
      tempEntitySub = {
        entityID: entityID,
        subscriberID: subscriberID,
        status: true,
        permissions: [permissionID],
      };
      setEntitySubscribers([...tempEntitySubs, tempEntitySub]);
    }
    handleChange(subscriberIndex, tempEntitySub);
  };

  const handleChange = async (subscriberIndex, newEntitySubscriber) => {
    if (subscribers[subscriberIndex].entitySubscribers != undefined) {
      if (subscribers[subscriberIndex].entitySubscribers.length != 1) return;
      thirdPartyVendorService
        .updateEntitySubscriber(newEntitySubscriber)
        .then((res) => {
          subscribers[subscriberIndex].entitySubscribers = [res.data];
          setSelectedDropdown(subscribers);
        })
        .catch(() => {
          enqueueSnackbar("Save failed", {
            variant: "error",
            tag: "SaveFailed",
          });
        });
    } else {
      thirdPartyVendorService
        .createEntitySubscriber(newEntitySubscriber)
        .then((res) => {
          subscribers[subscriberIndex].entitySubscribers = [res.data];
          setSelectedDropdown(subscribers);
        })
        .catch(() => {
          enqueueSnackbar("Save failed", {
            variant: "error",
            tag: "SaveFailed",
          });
        });
    }
  };

  return (
    <Grid className={classes.panelRoot} container>
      <Accordion
        data-tag="integrators"
        expanded={thirdPartyExpanded}
        TransitionProps={{ unmountOnExit: true }}
        className={classes.fullWidthAccordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={toggleSubscriberExpanded}>
          <Typography className={classes.heading}>Integrators</Typography>
          <span className={classes.toggleVisibilityButton} >
            <Button
              className={clsx(["view-toggle", (enabledOnly ? "enabled" : "all")])}
              size="small"
              variant="outlined"
              onClick={toggleEnabledOnly}
            ><FontAwesomeIcon
                icon={faEye}
                style={{ fontSize: "14", paddingRight: 4 }}
              />{enabledOnly ? "Enabled" : "All"}
            </Button>
          </span>
        </AccordionSummary>
        <AccordionDetails>
          {subscribers?.length <= 0 ? (
            <Typography>There were no third party integrators found.</Typography>
          ) : (
            <Grid container spacing={2}>
              {showIntegratorPermissions ? (
                subscribers.map((s, sIndex) => (
                  <Grid key={`subscriber_${sIndex}`} item xs={12} sm={6} md={4} lg={4} xl={4}
                    className={clsx(classes.gridPaper,
                      enabledOnly
                        ? getEntitySubscriberStatus(s.subscriberID)
                          ? ""
                          : "dontshow"
                        : ""
                    )}>
                    <div data-value={`integrator-${s.description}`}>
                      <Paper
                        style={{ marginBottom: 15, position: "relative" }}
                        className={clsx([classes.paperWrapper, getEntitySubscriberStatus(s.subscriberID) ? "selected" : ""])}>
                        <div
                          style={{ cursor: isAdmin ? "pointer" : "inherit", paddingTop: 6, marginLeft: 8, overflow: "hidden" }}
                          className={clsx([classes.switchLabelIntegrators])}
                          data-selected={getEntitySubscriberStatus(s.subscriberID)}
                          onClick={() => handleStatusChange(sIndex, s.subscriberID)
                          }>{s.description}</div>
                        {isAdmin && (
                          <div style={{ position: "absolute", top: 0, right: 8 }}>
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={!isAdmin}
                                  data-test-id={s.subscriberID}
                                  name={s.description}
                                  className="switch"
                                  data-checked={getEntitySubscriberStatus(s.subscriberID)}
                                  onChange={() => handleStatusChange(sIndex, s.subscriberID)}
                                  checked={getEntitySubscriberStatus(s.subscriberID)}
                                  color="primary"
                                />
                              }
                              classes={{ label: classes.switchLabel }}
                              labelPlacement="start"
                            />
                          </div>
                        )}
                        <div style={{ marginLeft: 8 }}>
                          <FormGroup>
                            <Grid container style={{ marginTop: 15 }}>
                              {subscriberPermissions?.map((sp, spIndex) => (
                                <Grid item xs={12} sm={12} md={6} key={`${s.subscriberID}_permision_${spIndex}`}>
                                  <FormControlLabel
                                    key={spIndex}
                                    control={
                                      <Checkbox
                                        data-test-id="perm-check"
                                        size="small"
                                        disabled={!isAdmin}
                                        name={sp.permissionName}
                                        data-value={sp.permissionName}
                                        data-checked={isSubscriberPermissionEnabled(
                                          s.subscriberID,
                                          sp.permissionID
                                        )}
                                        onChange={() =>
                                          handlePermissionChange(
                                            sIndex,
                                            s.subscriberID,
                                            sp.permissionID
                                          )
                                        }
                                        checked={isSubscriberPermissionEnabled(
                                          s.subscriberID,
                                          sp.permissionID
                                        )}
                                        color="primary"
                                      />
                                    }
                                    label={<span className={clsx([
                                      classes.apiLabel,
                                      getEntitySubscriberStatus(s.subscriberID) ? "enabled" : "not-enabled",
                                      isSubscriberPermissionEnabled(s.subscriberID, sp.permissionID) ? "selected" : "",
                                    ])} style={{ fontSize: "smaller" }}>{sp.friendlyName}</span>}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </FormGroup>
                        </div>
                      </Paper>
                    </div>
                  </Grid>
                ))
              ) : (
                <Dropdown
                  classes={{ root: classes.dropdown }}
                  name={"Third party integrators"}
                  options={subscribers?.map((row) => row.description)}
                  handleChange={(e) => handleSelected(e)}
                  checkedItems={selected}
                  checkboxColor="primary"
                />
              )}
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

EnableThirdParties.defaultProps = {
  subscribers: [],
};

export default EnableThirdParties;
