import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "@material-ui/icons";

export const useStyles = makeStyles((theme) => ({

	root: {},
	component: {
		fontSize: "1.15rem",
		fontWeight:400,
		marginLeft:5,
	},
	flag: {
		marginLeft:"20px",
		// uncomment when you get enabling toggling
		// color: theme.palette.text.secondary,
		color: theme.palette.text.primary,
		fontWeight: 300,
	},
	enabled : {
		color: theme.palette.text.primary,
	}
}));