import { makeStyles } from "@material-ui/core/styles";
import FlexibleParkingAccountForm from "../../ContractDetails/FlexibleParkingAccount";

export const useStyles = makeStyles((theme) => ({
  root: {},

  deleteThreshold: {
    display: "flex",
    justifyContent: "flex-end"
  },
  progressIndicator: {
    color: "#fafafa",
  },
  listItemDiv: {
    width: "100%",
    overflow: "auto",
  },
  thresholdRatesContainer: {
    margin: theme.spacing(1),
  },
}));
