import { makeStyles, lighten, darken } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    flexInput: { margin: theme.spacing(1) },
    addressLine1: { flex: 1, flexBasis: "100%" },
    addressLine2: { flex: 1, flexBasis: "100%" },
    country: { flex: 1, flexBasis: "100%" },
    city: { flex: 1, flexBasis: "100px" },
    stateDropdown: {
        flex: 1,
        flexBasis: "100px",
        [theme.breakpoints.down("sm")]: {
            width: "100px",
        },
    },
    postalCode: { flex: 1, flexBasis: "100px" },
    selectedListItem: {
        '&.scope.selected':{
            backgroundColor:lighten(theme.palette.amano.main,.6),
            color:darken(theme.palette.amano.main,.4),
          }
    },
}));
