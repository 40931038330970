import React, { useState, useEffect, useCallback, useReducer } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import clsx from "clsx";
import { IconButton, Grid, Typography } from "@material-ui/core";
import apiClient from "../../../auth/apiClient";
import CreditCardOnFileService from "../../../services/CreditCardOnFileService";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import DeleteIcon from "@material-ui/icons/Delete";
import Title from "../../Title";
import ColoredLine from "../../ColoredLine";
import { isUndefined } from "lodash";
import CardOnFileButton from "./CardOnFileButton";
import { LinearProgress } from "@material-ui/core";
import useHubContext from "../../../hooks/useHubContext";
import { CARD_TRANSACTION_STATE, CARD_ON_FILE_STATE_CHANGE } from "../../../constants"

const cardOnFileFormState = {
  showReplaceButton: false,
  cardOnFile: undefined,
  transactionState: undefined,
  displayMessage: undefined
};
function cardOnFileFormReducer(state, { type, payload }) {
  switch (type) {
    case "RETRIEVED_CARD_DATA":
      return {
        ...state,
        cardOnFile: payload.cardOnFile,
        showReplaceButton: true,
        transactionState: payload.transactionState
      };
    case "NO_CARD_ON_FILE":
      return {
        ...state,
        cardOnFile: payload.cardOnFile,
        showReplaceButton: false,
        transactionState: payload.transactionState,
        displayMessage: payload.displayMessage
      };
    default:
      return state;
  }
}

const creditCardOnFileService = new CreditCardOnFileService(apiClient);

const CardOnFileForm = ({ contractID, accessHolderID }) => {
  const enqueueSnackbar = useEnqueueSnackbar();

  const { portalHub, Connected: PortalHubConnected } = useHubContext();
  const [loading, setLoading] = useState(true);

  const [state, dispatch] = useReducer(
    cardOnFileFormReducer,
    cardOnFileFormState
  );

  const classes = useStyles();

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  const fetchCardOnFile = useCallback(async () => {
    let response;
    let isCardonFileAvailable = false;
    let contractTransactionState;

    try {
      response = await creditCardOnFileService.getCreditCardOnFile(contractID);
    } catch (ex) {
      if (ex.response?.status !== 404) {
        enqueueSnackbar("Failed to get card on file", {
          variant: "error",
          tag: "getCardOnFile",
        });
      }
    }

    try {

      if (response?.data) {
        var expDate = new Date(response?.data.expirationDate);
        var month = padTo2Digits(expDate.getMonth() + 1);
        var year = expDate.getFullYear();
        response.data.expirationDate = month + "/" + year;
        response.data.maskedCardNumber = "**** **** **** " + response.data.last4;
        response.data.cardType = getCardByCardType(response.data.creditCardDescription);

        isCardonFileAvailable = true;
      }

      try {
        const transactionStateResponse = await creditCardOnFileService.getTransactionState(contractID);
        contractTransactionState = transactionStateResponse?.data;
      } catch (error) {
        console.error("Error fetching transaction state");
      }

      if (isCardonFileAvailable) {
        dispatch({
          type: "RETRIEVED_CARD_DATA",
          payload: getPayloadObject(response.data, contractTransactionState)
        });
      } else {
        let displayMessage;
        switch (contractTransactionState) {
          case CARD_TRANSACTION_STATE.PENDING:
            displayMessage = "Card On File in progress... Please wait";
            break;
          case CARD_TRANSACTION_STATE.FAIL:
            displayMessage = "Card On File failed... Please retry";
            break;
        }
        dispatch({
          type: "NO_CARD_ON_FILE",
          payload: getPayloadObject(null, contractTransactionState, displayMessage)
        });
      }      
      setLoading(false);
    } catch (ex) {
      console.log("Error processing card on file  with its transaction state");
      setLoading(false);
      return;
    }
  }, [contractID]);

  function getPayloadObject(cardonFileData, transactionStateData, displayMessageData) {
    return {
      cardOnFile: cardonFileData,
      transactionState: transactionStateData,
      displayMessage: displayMessageData
    };
  }

  function getCardByCardType(cardType) {
    let imagePath;
    switch (cardType?.toLowerCase()) {
      case "visa":
        imagePath = require("../../../assets/creditcards/visa.png");
        break;
      case "mastercard":
        imagePath = require("../../../assets/creditcards/mastercard.png");
        break;
      case "discover":
        imagePath = require("../../../assets/creditcards/discover.png");
        break;
      case "american express":
        imagePath = require("../../../assets/creditcards/americanexpress.png");
        break;
      default:
        imagePath = require("../../../assets/creditcards/other.png");
        break;
    }
    return {
      imageSrc: imagePath,
      name: cardType
    };
  }

  useEffect(() => {
    if (!PortalHubConnected) return;

    portalHub.subscribe(CARD_ON_FILE_STATE_CHANGE, (data) => {
      console.log("Received hub topic:CARD_ON_FILE_STATE_CHANGE with details", data);
      var dataJson = JSON.parse(data);
      var message = JSON.parse(dataJson.Message);
      if (message.ContractID === contractID) {
        console.log("Card On File Transaction State changed to ", message.TransactionState);
        fetchCardOnFile();
      }
    });

    return () => {
      portalHub.unsubscribe(CARD_ON_FILE_STATE_CHANGE);
    };
  }, [PortalHubConnected, contractID]);

  useEffect(() => {
    if (!isUndefined(contractID)) {
      setLoading(true);
      fetchCardOnFile();
    }
  }, [contractID, fetchCardOnFile]);

  const handleDeleteCreditCard = async () => {
    try {
      const result = await creditCardOnFileService.deleteCreditCardOnFile(
        contractID
      );
      if (result.status === 200) {
        dispatch({
          type: "NO_CARD_ON_FILE",
          payload: getPayloadObject(null)
        });
        enqueueSnackbar("Successfully deleted credit card", {
          variant: "success",
          tag: "configureAcceptedDenomSuccess",
        });
      }
    } catch (err) {
      enqueueSnackbar("Failed to delete credit card", {
        variant: "error",
        tag: "deleteCreditCardError",
      });
      return;
    }
  };

  return (
    <>
      <div className={clsx("cardonfile-form-content", classes.contentRoot)}>
        <Grid
          container
          spacing={2}
          className={clsx("cardonfile-container", classes.cardOnFileContainer)}
        >
          <Grid item xs={9}>
            <div className={clsx("header", classes.header)}>
              <Title className={clsx("cardonfile-title")} noGutter>
                Card on File
              </Title>
            </div>
          </Grid>
          <Grid item xs={3}>
            {state.transactionState != CARD_TRANSACTION_STATE.PENDING && <CardOnFileButton
              contractID={contractID}
              accessHolderID={accessHolderID}
              showReplaceButton={state.showReplaceButton}
            />}
          </Grid>
        </Grid>
        <ColoredLine />
        {state.cardOnFile ? (
          <Grid
            container
            spacing={2}
            className={clsx("cardonfile-card-container", classes.cardContainer)}
          >
            <Grid item xs={4}>
              <img src={state.cardOnFile.cardType.imageSrc} alt={state.cardOnFile.cardType.name}></img>
            </Grid>
            <Grid item xs={6}>
              <Typography
                role="card-on-file-desc"
                variant="body1"
                align="left"
                className={clsx(
                  "cardonfile-card-typo",
                  classes.cardTypoContainer
                )}
                component='div'
              >
                <div xs={12}>
                  {state.cardOnFile?.maskedCardNumber}
                </div>
                <div xs={12}>
                  Exp.{" "} {state.cardOnFile?.expirationDate}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {state.cardOnFile ? (
                <IconButton
                  className={clsx("edit-cardonfile-delete-btn")}
                  color="secondary"
                  variant="contained"
                  onClick={handleDeleteCreditCard}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            className={clsx("card-container", classes.cardContainer)}
          >
            {loading ? (
              <LinearProgress style={{ width: "100%", opacity: 0.5 }} data-testid="loading-progress" />
            ) : (
              <Typography
                variant="body1"
                align="left"
                className={clsx("cardonfile-card-typo")}
              >
                {state.displayMessage ?? "None"}
              </Typography>
            )}
          </Grid>
        )}
      </div>
    </>
  );
};

CardOnFileForm.propTypes = {
  toggleModal: PropTypes.func,
  currentBalance: PropTypes.number,
  setCurrentBalance: PropTypes.func,
  contractID: PropTypes.string,
  entityID: PropTypes.string,
  fundType: PropTypes.number,
  fundID: PropTypes.string,
  setFundID: PropTypes.func,
};

export default CardOnFileForm;
