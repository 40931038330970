import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "unset",
      display: "block",
    },
  },
  amountContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "10px",
  },
  commentContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "10px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
    marginTop: "10px",
  },
  cancelButton: {
    marginLeft: "5px",
  },
  balanceAdjustmentContainer: {
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  hideBalance: {
    visibility: "hidden",
  },
  showBalance: {
    visibility: "visibile",
  },
}));
