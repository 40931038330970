import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { useStyles } from "./featureflagdrawercomponent.style";
import clsx from "clsx";

import {
  List,
  ListItemIcon,
  Switch,
  ListItemText,
  Collapse,
  ListItem
} from "@material-ui/core";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FeatureFlagDrawerFlag from "./featureflagdrawerflag";
import { FlagFormContext } from "../flagformcontextprovider";


const FeatureFlagDrawerComponent = ({ component }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(true);

  const { updateState } = useContext(FlagFormContext);

  return (
    <>
      <ListItem onClick={() => setDrawerOpen(!drawerOpen)}
				data-testid="component-item" className={clsx("feature-component-entry")} data-id={component.fullPath} k>
        <ListItemText primary={component.name} classes={{primary:classes.component}} />
        <Switch
          edge="end"
          color="primary"
          onClick={e => e.stopPropagation()}
          onChange={e => updateState(component.fullPath, e.target.checked)}
					className={clsx("feature-component-switch")}
					data-id={component.fullPath} 
          checked={!!component.enabled}
        />
        {drawerOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
      </ListItem>
      <Collapse in={drawerOpen} timeout="auto" unmountOnExit >
        <List component="div" disablePadding>
          {component.featureComponentFlags.map(f =>
            <FeatureFlagDrawerFlag key={f.fullPath} flag={f} />)
          }
        </List>
      </Collapse>
    </>

  );
};

FeatureFlagDrawerComponent.defaultProps = {};
FeatureFlagDrawerComponent.propTypes = { component: PropTypes.object };

export default React.memo(FeatureFlagDrawerComponent);
