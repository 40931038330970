import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: {},

    card: {
		position: "relative",
        padding:5,
        minWidth: 150,
        maxWidth:175,
        width:175,
        fontWeight: "bold",

        '&[data-tiled="true"]': {
            //height: "200px",

        },
        '&[data-tiled="false"]': {
            //height:"100px",
        },
    },

    feature:{
        fontWeight:"bold",
        color: theme.palette.primary.main,
        fontSize:"1.45rem",
    },
    component:{

    },
    flag:{},


    cardHeader:{
        cursor:"pointer",
    },
    header: {
        marginTop:15,
        marginLeft:12,
    },
    headerDesc:{
        marginLeft:12,
        marginBottom:10,
        marginTop:4,
        fontStyle:"italic",
    },
    headerfName:{
        color: theme.palette.primary.light,
        fontWeight:"bold",
    },
    featureDrawer: {
        width: "100%",
        minWidth:"100%",
        [theme.breakpoints.up('sm')]: {
            width: "35vw",
            minWidth: 400,
            flexShrink: 0,
          },
    },
    disabled: {
        backgroundColor: theme.palette.action.selected,
    },
    list: {
        flexGrow: 1,
    },
    btnGrid: {
        justifyContent: 'right',
				padding: 20,
				gridGap: 10
    }
}));