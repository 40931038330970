import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import {
	Tabs,
	Tab,
	Grid,
	Box,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "./index.style";

import FeatureFlagCard from "./FeatureFlagCard/featureflagcard";
import { FlagFormContext } from "./flagformcontextprovider";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";


function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					{children}
				</Box>
			)}
		</div>
	);
}

const EnableFeatureFlags = () => {
	const { formState, isError } = useContext(FlagFormContext);

	const classes = useStyles();
	const enqueueSnackbar = useEnqueueSnackbar();

	useEffect(()=> {
		if(isError) {
			enqueueSnackbar("Failed to retrieve feature groups", {
				variant: "error",
				tag: "fetchFeatureGroupsError"
			});
		}
	}, [isError])

	const [selectedGroup, setSelectedGroup] = useState(0);

	const handleTabChange = (event, newValue) => {
		setSelectedGroup(newValue);
	};

	return (
		<Accordion defaultExpanded={true} className={clsx("feature-panel", classes.root)}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				id="panel1a-header"
				test-id="feature-flag-accordian"
				className={clsx("feature-panel-header")}
			>
				<Typography className={classes.heading}>Features</Typography>
			</AccordionSummary>
			<AccordionDetails className={clsx("feature-panel", classes.details)}>
				<Tabs className={classes.tabs} value={selectedGroup} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
					{
						formState.map((g, i) =>
							<Tab className={clsx("group-tab")} data-id={`${g.name}`} key={`${g.name}-tab`} label={g.name} value={i} />
						)
					}
				</Tabs>
				{
					formState.map((fg, i) =>
						<TabPanel value={selectedGroup} index={i} key={`${fg.name}-content`}>
							<Grid container spacing={2}>
								{
									fg.features.map(f =>
										<Grid item key={f.name} >
											<FeatureFlagCard feature={f} />
										</Grid>
									)
								}
							</Grid>
						</TabPanel>
					)
				}
			</AccordionDetails>
		</Accordion>
	);
};

export default EnableFeatureFlags;
