import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import { useStyles } from "./featureflagstatusbar.style.js";
import PropTypes from "prop-types";
import _ from "lodash";

import clsx from "clsx";
//import clsx from "../../../../node_modules/clsx/clsx";

//now just pass it   "off" or "on" for whether it has features enabled
//it will change it to
const FeatureFlagStatusBar = ({ status }) => {
	const classes = useStyles();
	const statusClass = status ? "on" : "off";

	return (
		<>
			<div
				className={clsx([classes.status, statusClass])}
				data-value={statusClass}
			/>
		</>
	);
};

FeatureFlagStatusBar.defaultProps = { status: "off" };
FeatureFlagStatusBar.propTypes = { status: PropTypes.string };

export default FeatureFlagStatusBar;
