import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";
import AddressSubform from "./AddressSubform";
import { TextField } from "@material-ui/core";
import EmailSubform from "./EmailSubform";
import PhoneSubform from "./PhoneSubform";
import clsx from "clsx";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";

const ContactSubform = ({
  disabled,
  requiredFields
}) => {
  const classes = useStyles();
  const { control, setValue, trigger, formState: { errors } } = useFormContext();

  const { fields: addressFields } = useFieldArray({
    control,
    name: "contactInfo.addresses",
  });

  const { fields: emailFields } = useFieldArray({
    control,
    name: "contactInfo.emails",
  });

  const { fields: phoneNumberFields } = useFieldArray({
    control,
    name: "contactInfo.phoneNumbers",
  });

  const formatTextField = (e) => {
    const val = (e.target.value || "").replace(/\s+/gi, " ");
    setValue(e.target.name, val.trim());
    trigger(e.target.name);
  };

  return (
    <>
      <div className={clsx("name-container", classes.nameContainer)}>
        <Controller
          name="contactInfo.firstName"
          control={control}
          render={({ field }) => <TextField
            {...field}
            id="firstName"
            size="small"
            required={requiredFields?.includes("firstName") ? true : false}
            className={clsx("firstname", classes.firstName)}
            label="First Name"
            variant="outlined"
            onBlur={formatTextField}
            error={!!errors.contactInfo?.firstName}
            helperText={errors.contactInfo?.firstName && errors.contactInfo?.firstName.message}
            inputProps={{ "aria-label": "First Name" }}
            InputProps={{
              readOnly: Boolean(disabled),
              "aria-readonly": Boolean(disabled),
              disabled: Boolean(disabled),
            }}
          />}
        />

        <Controller
          name="contactInfo.lastName"
          control={control}
          render={({ field }) => <TextField
            {...field}
            size="small"
            className={clsx("lastname", classes.lastName)}
            label="Last Name"
            variant="outlined"
            required={requiredFields?.includes("lastName") ? true : false}
            onBlur={formatTextField}
            error={!!errors.contactInfo?.lastName}
            helperText={errors.contactInfo?.lastName && errors.contactInfo?.lastName.message}
            inputProps={{ "aria-label": "Last Name" }}
            InputProps={{
              readOnly: Boolean(disabled),
              "aria-readonly": Boolean(disabled),
              disabled: Boolean(disabled),
            }}
          />}
        />
      </div>

      {addressFields?.map((address, index) => {
        return (
          <div key={address.id} className={clsx("address-container", classes.addressContainer)}>
            <AddressSubform index={index} disabled={disabled} />
          </div>
        )
      })}

      <div className={clsx("phone-email-container", classes.phoneEmailContainer)}>
        {phoneNumberFields?.map((phoneNumber, index) => {
          return (
            <div key={phoneNumber.id} className={clsx("email-container", classes.phoneContainer)}>
              <PhoneSubform index={index} disabled={disabled} />
            </div>
          )
        })}
        {emailFields?.map((email, index) => {
          return (
            <div key={email.id} className={clsx("email-container", classes.emailContainer)}>
              <EmailSubform index={index} disabled={disabled} />
            </div>
          )
        })}
      </div>
    </>
  );
};

ContactSubform.defaultProps = {
  disabled: false,
};

ContactSubform.propTypes = {
  disabled: PropTypes.bool,
};

export default ContactSubform;
