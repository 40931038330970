import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Button, MenuItem, TextField, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { isEmpty, isUndefined } from "lodash";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";
import { CredentialSchema } from ".";
import useHasPermissions from "../../../hooks/useHasPermissions";
import { filterInt } from "../../../utils/filterInt";
import CredentialEmailButton from "../../Buttons/CredentialEmail";
import SingleSelectDropdown from "../../Dropdowns/SingleSelect";
import { generateUUID } from "../../Rate";
import { isAllowedCredentialType } from "./index";
import { useStyles } from "./style";
import clsx from "clsx";
import _ from "lodash";

const InlineAccessCredentialForm = ({
  assignedAccessHolderName,
  credentialTypes,
  data,
  disabled,
  emailSettings,
  onDelete,
  onFormSubmit,
}) => {
  const classes = useStyles();
  const { hasPermissions } = useHasPermissions();
  const credentialDelete = hasPermissions(
    ["accessholders.delete", "inventory.delete"],
    true
  );
  const credentialsAdd = hasPermissions(
    ["accessholders.add", "inventory.add"],
    true
  );
  const credentialsEdit = hasPermissions(
    ["accessholders.edit", "inventory.edit"],
    true
  );
  const [isCreatedCredential, setIsCreatedCredential] = useState(
    data?.credentialID !== undefined ?? false
  );
  const [transientValues, setTransientValues] = useState(data);
  const {
    values,
    submitForm,
    errors,
    handleChange,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: data,
    validationSchema: CredentialSchema,
    onSubmit: onFormSubmit,
    enableReinitialize: true,
  });

  const handleCodeChange = (value) => {
    setFieldValue("systemCode", value);
  };

  const saveCredential = () => {
    submitForm();

    setTimeout(() => {
      resetForm();
    }, 200);
  };

  const handleCredentialRefChange = (value) => {
    if (parseInt(value) === 0 || isNaN(filterInt(value)))
      setFieldValue("credentialReference", value);
    else setFieldValue("credentialReference", parseInt(value));
  };

  const storeTransientValue = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTransientValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCredentialTypeChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    await setFieldValue(name, value);

    if (value?.toLowerCase() === "barcode") {
      await setFieldValue("systemCode", "");
      return;
    }
  };

  const generateBarcode = async () => {
    const barcode = generateUUID();
    await setFieldValue("credentialReference", barcode);
    await setFieldValue("systemCode", "");
  };

  const handleDeleteClick = () => {
    onDelete(values.accessHolderID, values.contractID, values.credentialID);
  };

  const formatEmail = () => {
    var emailBody = "<div style='white-space:pre-wrap'>";
    emailBody += emailSettings.template;
    emailBody = emailBody.replace(
      "<FirstName>",
      assignedAccessHolderName.first
    );
    emailBody = emailBody.replace("<LastName>", assignedAccessHolderName.last);
    emailBody += "</div>";
    return emailBody;
  };

  const formatQR = () => {
    var qr = ReactDOMServer.renderToString(
      <QRCode value={values.credentialReference} size="96" />
    );
    return qr;
  };

  const shouldBeDisabled = () => {
    if (disabled) return true;
    if (isUndefined(values.credentialID)) {
      return !credentialsAdd;
    } else {
      return !credentialsEdit;
    }
  };

  const cancelSelections = () => {
    setFieldValue("credentialType", "");
    resetForm();
  };

  return (
    <div className={clsx("inline-credential-form", classes.inlineRoot)}>
      {values?.credentialType &&
      values?.credentialType?.toLowerCase() === "barcode" &&
      !shouldBeDisabled() &&
      _.isEmpty(values.credentialReference) ? (
        <Button
          className={clsx("generateBarcodeBtn")}
          color="primary"
          variant="contained"
          onClick={generateBarcode}
        >
          Generate
        </Button>
      ) : (
        <></>
      )}
      {values?.credentialType && (
        <TextField
          data-testid="credentialReferenceTextBox"
          className={clsx("credential-reference", classes.flexInput)}
          name="credentialReference"
          label="Credential Reference"
          onFocus={storeTransientValue}
          onChange={handleChange}
          onBlur={(e) => handleCredentialRefChange(e.target.value)}
          value={values["credentialReference"] ?? ""}
          error={errors && errors.credentialReference ? true : false}
          helperText={errors && errors.credentialReference}
          disabled={shouldBeDisabled()}
          InputProps={{
            readOnly: Boolean(shouldBeDisabled()),
            "aria-readonly": Boolean(shouldBeDisabled()),
            disabled: Boolean(shouldBeDisabled()),
          }}
        />
      )}
      {values?.credentialType?.toLowerCase() !== "barcode" &&
        values?.credentialType && (
          <SingleSelectDropdown
            className={clsx("flexDropdown", "flex-input", classes.flexDropdown)}
            name="System Code"
            id="systemCode"
            options={[...data.availableCodes, { name: "None", value: "" }]}
            onSelect={handleCodeChange}
            handleOpen={storeTransientValue}
            value={values.systemCode ?? ""}
            displayEmpty
            error={errors && errors.systemCode ? true : false}
            helperText={errors && errors.systemCode}
          />
        )}
      {!values?.credentialType ? (
        <TextField
          data-testid="credentialTypeTextBox"
          id="credential-type"
          className={clsx("credentialType", classes.flexInput)}
          name="credentialType"
          label="Type"
          select
          SelectProps={{
            SelectDisplayProps: { "data-testid": "credential-type" },
          }}
          onChange={handleCredentialTypeChange}
          onFocus={storeTransientValue}
          defaultValue={values["credentialType"] ?? ""}
          value={values["credentialType"] ?? ""}
          error={errors && errors.credentialType ? true : false}
          helperText={errors && errors.credentialType}
          disabled={shouldBeDisabled()}
          InputProps={{
            readOnly: Boolean(shouldBeDisabled()),
            "aria-readonly": Boolean(shouldBeDisabled()),
            disabled: Boolean(shouldBeDisabled()),
          }}
        >
          {credentialTypes?.map((type, index) => {
            return (
              isAllowedCredentialType(type) && (
                <MenuItem
                  key={index}
                  value={type}
                  className={clsx(`menu-item-${type}`)}
                >
                  {type === "Prox" ? "AVI/Prox/Magstripe" : type}
                </MenuItem>
              )
            );
          })}
        </TextField>
      ) : (
        <Typography
          id="selected-type"
          className={clsx("selectedType", classes.selectedType)}
        >
          {values.credentialType}
        </Typography>
      )}
      {values?.credentialType && !shouldBeDisabled() && (
        <Button
          data-testid="InlineAccessCredentialFormSaveButton"
          className={clsx("saveCredentialBtn")}
          color="primary"
          variant="contained"
          onClick={saveCredential}
          disabled={!isEmpty(errors)}
        >
          Save
        </Button>
      )}
      {!disabled && values.credentialID && credentialDelete ? (
        <Button
          data-testid="InlineAccessCredentialFormDeleteButton"
          className={
            values.credentialType?.toLowerCase() === "barcode" &&
            values.credentialID
              ? clsx("deleteBtn", "deleteBtnWithoutRightSpace")
              : clsx("deleteBtn", "deleteBtnWithRightSpace")
          }
          color="secondary"
          variant="contained"
          onClick={handleDeleteClick}
        >
          Delete
        </Button>
      ) : (
        <></>
      )}
      {values?.credentialType && !isCreatedCredential && (
        <Button
          data-testid="InlineAccessCredentialFormCancelButton"
          className={clsx("cancelSelectionsBtn")}
          color="secondary"
          variant="contained"
          onClick={cancelSelections}
        >
          Cancel
        </Button>
      )}
      {!disabled &&
      values?.credentialType &&
      values.credentialType?.toLowerCase() === "barcode" &&
      values.credentialID ? (
        <CredentialEmailButton
          className={clsx("barcode-email-btn")}
          from={emailSettings?.from}
          displayname={emailSettings?.displayname}
          to={emailSettings?.to}
          ccReplyTo={emailSettings?.ccReplyTo}
          replyTo={emailSettings?.replyTo}
          subject="Barcode Credential"
          body={formatEmail()}
          qrCode={formatQR()}
          toAddressMessage={
            "Please add an email address to the access holder's contact information."
          }
        />
      ) : (
        <></>
      )}
    </div>
  );
};

InlineAccessCredentialForm.defaultProps = {
  onFormSubmit: () => {},
  onDelete: () => {},
  onEmailClick: () => {},
  emailSettings: {},
  data: {},
};

InlineAccessCredentialForm.propTypes = {
  emailSettings: {
    to: PropTypes.string.isRequired,
    ccReplyTo: PropTypes.string.isRequired,
  },
  assignedAccessHolderName: PropTypes.shape({
    first: PropTypes.string.isRequired,
    last: PropTypes.string,
  }),
};

export default InlineAccessCredentialForm;
