import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  contentRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      justifyContent: "unset",
      display: "block",
    },
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: 10,
    marginBottom:10
  },
  cardOnFileContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "0px",
  },
  deleteButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    width: "100%",
  },
  cancelButton: {
    marginLeft: "5px",
  },
  balanceAdjustmentContainer: {
    justifyContent: "flex-end",
    marginTop: "10px",
  },
  cardTypoContainer: {
    marginTop: "4px",
  },
  hideBalance: {
    visibility: "hidden",
  },
  showBalance: {
    visibility: "visibile",
  },
}));
