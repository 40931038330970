import React, { useEffect, useState } from 'react';
import useCurrentFacility from "../../../../hooks/useCurrentFacility";
import FeatureFlagService from "../../../../services/FeatureFlagService";
import apiClient from "../../../../auth/apiClient";

export const FlagFormContext = React.createContext();
export const FlagFormContextProvider = ({ children, entityId }) => {
	const [currentState, setCurrentState] = useState([]);
	const [formState, setFormState] = useState([]);
	const [currentChanges, setCurrentChanges] = useState([]);
	const [isError, setIsError] = useState(false);

	const featureFlagService = new FeatureFlagService(apiClient);

	const updateState = (featurepath, status) => {
		let changeArr = [...currentChanges];

		let stateChange = JSON.parse(JSON.stringify(formState));

		const pathArr = featurepath.split('.');

		const foundFeature = stateChange.map(f => f.features)?.flat()?.find(s => s.name == pathArr[0]);
		const foundChange = changeArr.find(i => i.featurePath == featurepath);

		switch(pathArr.length){
			case 1:  {
				if (foundChange) {
					if (foundChange.initialStatus == status) {
						changeArr = changeArr.filter(item => item !== foundChange)
					} else {
						foundChange.enabled = status;
					}
				} else {
					changeArr.push({
						featurePath: featurepath,
						enabled: status,
						initialStatus: foundFeature.enabled
					})
				}

				foundFeature.enabled = status;
				break;
			}

			case 2: {
				const foundFeatureComponent = foundFeature.featureComponents.find(s => s.name == pathArr[1]);
				if (foundChange) {
					if (foundChange.initialStatus == status) {
						changeArr = changeArr.filter(item => item !== foundChange)
					} else {
						foundChange.enabled = status;
					}
				} else {
					changeArr.push({
						featurePath: featurepath,
						enabled: status,
						initialStatus: foundFeatureComponent.enabled
					})
				}

				foundFeatureComponent.enabled = status;
				break;
			}
			case 3: {
				const foundFeatureComponentFlag = foundFeature.featureComponents.find(s => s.name == pathArr[1]).featureComponentFlags.find(s => s.name == pathArr[2]);
				if (foundChange) {
					if (foundChange.initialStatus == status) {
						changeArr = changeArr.filter(item => item !== foundChange)
					} else {
						foundChange.enabled = status;
					}
				} else {
					changeArr.push({
						featurePath: featurepath,
						enabled: status,
						initialStatus: foundFeatureComponentFlag.enabled
					})
				}

				foundFeatureComponentFlag.enabled = status;
				break;
			}
		}

		setCurrentChanges(changeArr);
		setFormState(stateChange);
	}

	const resetState =  () => {
		setFormState(currentState);
	}

	const submitChange = async () => {
		if (currentChanges.length > 0) {
			const result = await featureFlagService.upsertFlags(entityId, currentChanges);
			setCurrentState(formState);
			setCurrentChanges([]);

			return result;
		}
	}

	useEffect(() => {
		featureFlagService.getAllFeaturesEntityAdmin(entityId).then((result) => {
			setCurrentState(JSON.parse(JSON.stringify(result.data.featureGroupings)));
			setFormState(JSON.parse(JSON.stringify(result.data.featureGroupings)));
		}).catch((err) => setIsError(true));
	}, [])


	return (
		<FlagFormContext.Provider value={{
			currentState,
			formState,
			updateState,
			resetState,
			submitChange,
			isError
		}}>
			{children}
		</FlagFormContext.Provider>
	)
}
