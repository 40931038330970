import React, { useContext } from "react";
import _ from "lodash";
import clsx from "clsx";

import { useStyles } from "./featureflagdrawerflag.style.js";

import {
	Switch,
	ListItemText,
	ListItem
} from "@material-ui/core";
import PropTypes from 'prop-types';
import { FlagFormContext } from "../flagformcontextprovider";


const FeatureFlagDrawerFlag = ({ flag }) => {
	const classes = useStyles();

	const { updateState } = useContext(FlagFormContext);

	return (
		<ListItem className={clsx("flag-entry")} data-id={flag.fullPath} key={flag.fullPath} sx={{ pl: 8 }}>
			<ListItemText primary={flag.name}
				classes={{ primary: classes.flag }}
			/>
			<Switch
				className={clsx("flag-switch")}
				data-id={flag.fullPath}
				color="primary"
				edge="end"
				checked={!!flag.enabled}
				data-testid="flag-switch"
				onChange={e => updateState(flag.fullPath, e.target.checked)}
			/>
		</ListItem>
	);

};

FeatureFlagDrawerFlag.defaultProps = {};
FeatureFlagDrawerFlag.propTypes = { flag: PropTypes.object };

export default React.memo(FeatureFlagDrawerFlag);
