import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    root: {},

    status: {
        position:"aboslute",
        top: 0,
        right: 0,
        width: "100%",
        minHeight: 5,
        maxHeight: 5,
        height: 5,

        //the order of these matter, as more critical will replace less
        '&.off': {
            backgroundColor: theme.palette.action.disabledBackground,
        },
        '&.on': {
            backgroundColor: theme.palette.primary.light,
        },

    }

}));