import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  footer: {
    padding: theme.spacing(1, 0),
    marginTop: "auto",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.background.level2
        : theme.palette.grey[200]
  }
}));
