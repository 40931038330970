import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    "& label": {
      display: "block",
    },
  },
  groupCheckbox: {},
  groupBlock: {
    marginLeft: theme.spacing(2),
  },
  devicesBlock: {
    marginLeft: theme.spacing(4),
  },
  accordion: {
    marginBottom:24,

  }
}));
