import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, Button } from "@material-ui/core";
import { useStyles } from "./style";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import cloneDeep from "lodash/cloneDeep";
import { isUndefined } from "lodash";


const  ThresholdsForm = ({
  control,
  errors,
  thresholdFields, fieldsIndex, maxIndex, availableRates, currentRate, handleDelete, watch
}) => {
  const classes = useStyles();
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name,
    trim: true,
    ignoreCase: true,
  });

  const [selectedRate, setSelectedRate] = useState(isUndefined(currentRate) ? null : currentRate);

  useEffect(() => {
    if (availableRates?.length > 0) {
      const matchingRate = availableRates
        .filter((x) => currentRate === null || isUndefined(currentRate) ? x.rateBlobID === null : x.rateBlobID === currentRate)
        .at(0);
      setSelectedRate(matchingRate ?? null);
    }
  }, [availableRates, currentRate]);

  const handleSelectedRateChange = (rate) => {
    if (rate !== null) {
      setSelectedRate(rate);

      let thresholdCopy = cloneDeep(thresholdFields.fields[fieldsIndex]);
      thresholdCopy.rateBlobID = rate.rateBlobID;
      thresholdCopy.thresholdLimit = watchLimit;
      thresholdCopy.keepInThreshold = watchKeepInThreshold;
      thresholdFields.update(fieldsIndex, thresholdCopy);
    }
  };

  const watchLimit = watch(`accessGroupThresholds.${fieldsIndex}.thresholdLimit`);
  const watchKeepInThreshold = watch(`accessGroupThresholds.${fieldsIndex}.keepInThreshold`);

  return (
    <>
    <div>
      {thresholdFields?.fields?.map((thresholdField, index) => {
        return (
          <div key={thresholdField.id}>
            {index === fieldsIndex && (
              <div>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <Controller
                      name={`accessGroupThresholds.${index}.thresholdLimit`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                        id={`accessGroupThresholds.${index}.thresholdLimit`}
                          fullWidth
                          label="Limit"
                          {...field}
                          error={!!errors?.accessGroupThresholds && errors?.accessGroupThresholds[index]?.thresholdLimit}
                          helperText={!!errors?.accessGroupThresholds && errors?.accessGroupThresholds[index]?.thresholdLimit && errors?.accessGroupThresholds[index]?.thresholdLimit?.message}
                          type="text"
                          variant="outlined"
                          className={clsx("thresholdLimit")}
                          InputProps={{
                            inputProps: {
                              "data-testid": "threshold-limit",
                              "aria-label": "thresholdLimit",
                            },
                          }} />
                      )} />
                  </Grid>
                  <Grid item xs={12} lg={7}>
                    <Autocomplete
                      data-testid="threshold-rate-search"
                      className={clsx(["app-search", classes.autoComplete])}
                      options={availableRates ?? []}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option) => currentRate ? option.rateBlobID === null : option.rateBlobID === currentRate}
                      onChange={(e, data) => handleSelectedRateChange(data)}
                      value={selectedRate}
                      renderInput={(params) => (
                        <TextField
                          label={"Rate"}
                          fullWidth
                          {...params}
                          id="threshold-flexible-rate"
                          className={clsx("threshold-flexible-rate")}
                          variant="outlined" />
                      )}
                      filterOptions={filterOptions}
                      renderOption={(option) => (
                        <div
                          onClick={() => handleSelectedRateChange(option)}
                          className={clsx([classes.listItemDiv])}
                        >
                          <span data-id={option.rateID} data-value={option.rateBlobID}>
                            {option.name}
                          </span>
                        </div>
                      )} />
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <Controller
                      name={`accessGroupThresholds.${index}.current`}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          label="Current"
                          {...field}
                          type="text"
                          variant="outlined"
                          className={clsx("thresholdCurrent")}
                          disabled />
                      )} />
                  </Grid>
                </Grid>
                {index > 0 && (
                  <Grid item container xs={12} spacing={2}>
                    <Grid item xs={12} lg={5} className={clsx(classes.keepInThresholdContainer)}>
                      <label className={clsx("keep-in-threshold-checkbox-label", classes.checkboxLabel)}>
                        <Controller
                          control={control}
                          name={`accessGroupThresholds.${index}.keepInThreshold`}
                          render={({ field }) => (
                            <input
                              {...field}
                              className={clsx("keep-in-threshold-checkbox", classes.checkbox)}
                              type="checkbox"
                              checked={field.value}
                              data-checked={field.value} />
                          )} />
                        Keep In Threshold
                      </label>
                    </Grid>
                    {index === maxIndex && (
                      <Grid item xs={12} lg={7} className={clsx("delete-threshold-button", classes.deleteThreshold)}>
                        <Button
                          className={clsx("delete-btn")}
                          startIcon={<DeleteIcon className={clsx("delete-icon")} />}
                          variant="contained"
                          onClick={() => handleDelete(fieldsIndex)}
                        >
                          Delete Threshold
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
    </>
  );
}

export default ThresholdsForm