import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";

const Copyright = () => {
  return (
    <Box>
      <Box>
        <Typography variant="body2" color="textSecondary" align="right">
          Amano ONE
          <Typography
            style={{ paddingLeft: 6, paddingRight: 6 }}
            variant="body2"
            component="span"
            color="primary"
          >
            |
          </Typography>
          Copyright{" © "} {new Date().getFullYear()}
          <Typography
            style={{ paddingLeft: 6, paddingRight: 6 }}
            variant="body2"
            component="span"
            color="primary"
          >
            |
          </Typography>
          <Link
            color="inherit"
            target="_blank"
            href="https://www.amanomcgann.com/"
          >
            Amano McGann, Inc.
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Copyright;
