import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  giveSpace: {
    margin: "5px",
  },
  error: {
    color: theme.palette.error?.main,
    "& > label": {
      color: theme.palette.error?.main,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.error?.main} !important`,
    },
    "& #error-label": {
      marginLeft: theme.spacing(2),
      fontSize: ".75rem",
    },
  },
}));
