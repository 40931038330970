import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useStyles } from "./styles";
import { Grid, InputAdornment, TextField } from "@material-ui/core";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { isUndefined, isEqual } from "lodash";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import ContractService from "../../../../services/ContractService";
import apiClient from "../../../../auth/apiClient";
import * as c from "../../../../constants";

const contractService = new ContractService(apiClient);

const FlexibleParkingAccountForm = ({
  children,
  contractID,
  facilityID,
  disabled,
}) => {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [contractInfo, setContractInfo] = useState();

  const padNumericValues = (obj) => {
    Object.entries(obj).forEach(([key, val]) => {
      console.dir(parseFloat(val));
      if (!isNaN(parseFloat(val))) {
        const nonPaddedVal = `${val}`;
        if (nonPaddedVal.includes(".")) {
          const [,afterDecimal] = nonPaddedVal.split(".");
          if (afterDecimal.length == 1) {
            obj[key] = nonPaddedVal + "0";
          } else if (afterDecimal.length == 0) {
            obj[key] = nonPaddedVal + "00";
          }
        } else {
          obj[key] = nonPaddedVal + ".00";
        }
      }
    });
  }
  
  const fetchContractDetails = useCallback(async () => {
    if (!contractID || !facilityID) {
      setContractInfo();
      return;
    }
    let response;

    try {
      response = await contractService.getContractDetails(
        facilityID,
        contractID,
        c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"]
      );
    } catch {
      enqueueSnackbar("Failed to retrieve Flexible Parking Account", {
        variant: "error",
        tag: "getFlexibleParkingAccount",
      });
      return;
    }

    if (response?.data?.payload) {
      padNumericValues(response.data.payload);
      reset(response.data.payload);
      setContractInfo(response.data);
    }
  }, [contractID, facilityID]);

  useEffect(() => {
    if (!isUndefined(contractID) && !isUndefined(facilityID)) {
      fetchContractDetails();
    }
  }, [facilityID, contractID, fetchContractDetails]);

  const FlexibleParkingAccountSchema = Yup.object().shape({
    threshold: Yup.number()
      .typeError("Must be a number")
      .min(0, "Must be positive")
      .required("Required"),
    autoRecharge: Yup.boolean(),
    rechargeAmount: Yup.number().when("autoRecharge", {
      is: (autoRecharge) => autoRecharge,
      then: Yup.number()
        .typeError("Must be a number")
        .min(0, "Must be positive")
        .required("Required"),
      otherwise: Yup.number().nullable(),
    }),
    showFee: Yup.boolean(),
    showBalance: Yup.boolean(),
    allowNegative: Yup.boolean(),
    negativeLimit: Yup.number().when("allowNegative", {
      is: (allowNegative) => allowNegative,
      then: Yup.number()
        .typeError("Must be a number")
        .min(0, "Enter as a positive number")
        .required("Required"),
      otherwise: Yup.number().nullable(),
    }),
  });

  const defaultValues = {
    threshold: "0.00",
    autoRecharge: false,
    rechargeAmount: "0.00",
    showFee: false,
    showBalance: false,
    allowNegative: false,
    negativeLimit: "0.00",
  };

  const methods = useForm({
    resolver: yupResolver(FlexibleParkingAccountSchema),
    defaultValues: contractInfo?.payload ? contractInfo.payload : defaultValues,
    mode: "onChange",
  });

  const {
    control,
    resetField,
    watch,
    getValues,
    setValue,
    reset,
    formState: { isDirty, isValid, errors },
  } = methods;

  const createOrUpdateContractDetails = async (values) => {
    const isCreateDetails = contractInfo?.contractDetailsID ? false : true;
    try {
      let response;

      if (isCreateDetails) {
        response = await contractService.createContractDetails(
          facilityID,
          contractID,
          c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          {
            payload: values,
            contractID: contractID,
            typeID: c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          }
        );
      } else {
        response = await contractService.updateContractDetails(
          facilityID,
          contractID,
          c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          {
            payload: values,
            contractID: contractID,
            typeID: c.CONTRACT_DETAILS_TYPE["Flexible Parking Account"],
          }
        );
      }

      if (response?.data?.payload) {
        setContractInfo(response.data);
      }
    } catch {
      enqueueSnackbar(
        `Failed to  ${
          isCreateDetails ? "create" : "update"
        } flexible account details`,
        {
          variant: "error",
          tag: `${isCreateDetails ? "create" : "update"}ContractDetailsError`,
        }
      );
    }
  };

  const handleBlur = () => {
    const formValues = getValues();
    padNumericValues(formValues);
    setValue("threshold", formValues.threshold);
    setValue("negativeLimit", formValues.negativeLimit);
    setValue("rechargeAmount", formValues.rechargeAmount);
    formValues.threshold = parseFloat(getValues("threshold"));
    formValues.negativeLimit = parseFloat(getValues("negativeLimit"));
    formValues.rechargeAmount = parseFloat(getValues("rechargeAmount"));

    const currentValues = contractInfo?.payload ?? defaultValues;
    if (isDirty && isValid && !isEqual(currentValues, formValues)) {
      createOrUpdateContractDetails(formValues);
    }
  };

  const autoRechargeValue = watch("autoRecharge");
  const allowNegativeValue = watch("allowNegative");

  useEffect(() => {
    if (!autoRechargeValue) resetField("rechargeAmount", { defaultValues: "0.00" });
  }, [autoRechargeValue]);

  useEffect(() => {
    if (!allowNegativeValue) resetField("negativeLimit", { defaultValue: "0.00" });
  }, [allowNegativeValue]);

  return (
    <div className={clsx("card-content", classes.contentRoot)}>
      <Grid container item xs={12} lg={7} spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="threshold"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label="Low Threshold"
                {...field}
                error={!!errors.threshold}
                helperText={errors.threshold && errors.threshold.message}
                type="text"
                variant="outlined"
                disabled={disabled}
                className={clsx("threshold")}
                onBlur={handleBlur}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    "data-testid": "fpa-accessholder-threshold",
                  },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <label
            className={clsx(
              "auto-recharge-checkbox-label",
              classes.checkboxLabel
            )}
          >
            <Controller
              control={control}
              name="autoRecharge"
              render={({ field }) => (
                <input
                  {...field}
                  className={clsx("auto-recharge-checkbox", classes.checkbox)}
                  type="checkbox"
                  checked={field.value}
                  data-checked={field.value}
                  disabled={disabled}
                  onBlur={handleBlur}
                />
              )}
            />
            Auto recharge when balance is below low threshold.
          </label>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="rechargeAmount"
            control={control}
            render={({ field }) => (
              <TextField
                disabled={!autoRechargeValue || disabled}
                fullWidth
                label="Recharge Amount"
                {...field}
                error={!!errors.rechargeAmount}
                helperText={
                  errors.rechargeAmount && errors.rechargeAmount.message
                }
                type="text"
                variant="outlined"
                onBlur={handleBlur}
                className={clsx("rechargeAmount")}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    "data-testid": "fpa-accessholder-rechargeamount",
                  },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12} lg={5} spacing={2}>
        <Grid item xs={12}>
          <label
            className={clsx("show-fee-checkbox-label", classes.checkboxLabel)}
          >
            <Controller
              control={control}
              name="showFee"
              render={({ field }) => (
                <input
                  {...field}
                  className={clsx("show-fee-checkbox", classes.checkbox)}
                  type="checkbox"
                  checked={field.value}
                  data-checked={field.value}
                  disabled={disabled}
                  onBlur={handleBlur}
                />
              )}
            />
            Show fee on lane device screen
          </label>
        </Grid>
        <Grid item xs={12}>
          <label
            className={clsx(
              "show-balance-checkbox-label",
              classes.checkboxLabel
            )}
          >
            <Controller
              control={control}
              name="showBalance"
              render={({ field }) => (
                <input
                  {...field}
                  className={clsx("show-balance-checkbox", classes.checkbox)}
                  type="checkbox"
                  checked={field.value}
                  data-checked={field.value}
                  disabled={disabled}
                  onBlur={handleBlur}
                />
              )}
            />
            Show balance on lane device screen
          </label>
        </Grid>
        <Grid item xs={12}>
          <label
            className={clsx(
              "allow-negative-checkbox-label",
              classes.checkboxLabel
            )}
          >
            <Controller
              control={control}
              name="allowNegative"
              render={({ field }) => (
                <input
                  {...field}
                  className={clsx("allow-negative-checkbox", classes.checkbox)}
                  type="checkbox"
                  checked={field.value}
                  data-checked={field.value}
                  disabled={disabled}
                  onBlur={handleBlur}
                />
              )}
            />
            Allow account to go negative
          </label>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="negativeLimit"
            control={control}
            render={({ field }) => (
              <TextField
                disabled={!allowNegativeValue || disabled}
                fullWidth
                label="Limit"
                {...field}
                error={!!errors.negativeLimit}
                helperText={
                  errors.negativeLimit && errors.negativeLimit?.message
                }
                type="text"
                variant="outlined"
                onBlur={handleBlur}
                className={clsx("negativeLimit")}
                InputProps={{
                  inputProps: {
                    min: 0,
                    step: 0.01,
                    "data-testid": "fpa-accessholder-negativelimit",
                  },
                  inputMode: "numeric",
                  startAdornment: (
                    <InputAdornment position="start">-$</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {children}
    </div>
  );
};

FlexibleParkingAccountForm.propTypes = {
  children: PropTypes.node,
  contractID: PropTypes.string,
  facilityID: PropTypes.string,
};

export default FlexibleParkingAccountForm;
