import { Box, Button, Grid, MenuItem } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import { isUndefined } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import apiClient from "../../../auth/apiClient";
import { useConfirmationDialog } from "../../../hooks/useConfirmationDialog";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import useSettingsContext from "../../../hooks/useSettingsContext";
import CredentialService from "../../../services/CredentialService";
import { generateUUID } from "../../Rate";
import Title from "../../Title";
import InputField from "../InputField";
import InlineAccessCredentialForm from "./inline";
import { useStyles } from "./style";

const credentialService = new CredentialService(apiClient);

const ALLOWED_TYPES = ["prox", "barcode"];

export const isAllowedCredentialType = (type) => {
  if (!type) return false;
  return ALLOWED_TYPES.includes(
    type
      .replace(" ", "")
      .trim()
      .toLowerCase()
  );
};

export const CredentialSchema = Yup.object().shape({
  credentialReference: Yup.string().required("Required"),
  systemCode: Yup.string().nullable(),
});

const AccessCredentialForm = ({
  assignedAccessHolderName,
  data,
  disabled,
  email,
  inline,
  onCancel,
  onDelete,
  onSubmit,
}) => {
  const classes = useStyles();
  const [credentialTypes, setCredentialTypes] = useState([]);
  const isEditCredential = !isUndefined(data.credentialID);
  const facilityID = useSelector((state) => state.entities.ContextID);
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const { showDialog } = useConfirmationDialog();
  const { reducer } = useSettingsContext();
  const [values] = reducer;

  useEffect(() => {
    if (facilityID == null) return;
    getCredentialTypes(facilityID).then((info) => {
      setCredentialTypes(info);
    });
  }, [facilityID]);

  const getCredentialTypes = useCallback(
    async (facilityID) => {
      let response;
      try {
        response = await credentialService.GetCredentialTypes(facilityID);
        return response.data;
      } catch {
        enqueueSnackbar("Failed to retrieve credential types", {
          variant: "error",
          tag: "credentialFetchError",
        });
        return;
      }
    },
    [facilityID, credentialService.GetCredentialTypes]
  );

  const deleteCredential = async (accessHolderID, contractID, credentialID) => {
    const response = await showDialog({
      title: "Delete Credential",
      message: `Are you sure you want to remove this credential from this access holder?`,
      buttons: [
        { name: "Yes", color: "primary" },
        { name: "No", color: "secondary" },
      ],
    });
    if (response === "No") return;

    try {
      await credentialService.RemoveCredentialFromAccessHolder(
        accessHolderID,
        contractID,
        credentialID
      );
    } catch {
      enqueueSnackbar("Failed to remove credential from access holder", {
        variant: "error",
        tag: "credentialRemoveError",
      });
      return;
    }

    enqueueSnackbar("Successfully removed credential", {
      variant: "success",
    });
    onDelete(credentialID);
  };

  const createOrUpdateAccessCredential = async (values) => {
    let {
      credentialID,
      accessHolderID,
      contractID,
      credentialReference,
      credentialType,
      systemCode,
    } = values;
    if (!accessHolderID) {
      enqueueSnackbar("No access holder associated with this credential", {
        variant: "error",
        tag: "missingAccessHolderError",
      });
      return;
    }
    if (!contractID) {
      enqueueSnackbar("No contract associated with this credential", {
        variant: "error",
        tag: "missingContractError",
      });
      return;
    }

    if (
      (credentialType.toLowerCase() === "barcode" && !credentialReference) ||
      credentialReference === ""
    )
      credentialReference = generateUUID();

    const credential = {
      accessHolderID,
      contractID,
      credentialReference,
      credentialType,
      systemCode,
      facilityID : scopeAwareFacilityID
    };

    if (!isEditCredential) {
      // create and bind to access holder for specific contract
      try {
        await credentialService.CreateCredentialForAccessHolder(
          accessHolderID,
          contractID,
          credential
        );
        enqueueSnackbar("Successfully created credential", {
          variant: "success",
        });
      } catch (err) {
        if (err.response.status === 400)
          enqueueSnackbar(err.response.data, {
            variant: "error",
            tag: "credentialCreate400Error",
          });
        else
          enqueueSnackbar("Failed to create credential", {
            variant: "error",
            tag: "credentialCreateError",
          });
        return;
      }
    } else {
      try {
        credential.credentialID = credentialID;
        await credentialService.UpdateCredentialForAccessHolder(
          accessHolderID,
          contractID,
          credential
        );
        enqueueSnackbar("Successfully updated credential", {
          variant: "success",
        });
      } catch (err) {
        if (err.response.status === 400)
          enqueueSnackbar(err.response.data, {
            variant: "error",
            tag: "credentialUpdate400Error",
          });
        else
          enqueueSnackbar("Failed to update credential", {
            variant: "error",
            tag: "credentialUpdateError",
          });
        return;
      }
    }

    onSubmit();
  };

  if (inline === true) {
    return (
      <InlineAccessCredentialForm
        data={data}
        credentialTypes={credentialTypes}
        onFormSubmit={createOrUpdateAccessCredential}
        onDelete={deleteCredential}
        emailSettings={{ ...values?.emailSettings, to: email }}
        assignedAccessHolderName={assignedAccessHolderName}
        disabled={disabled}
      />
    );
  } else {
    return (
      <Box className={clsx("credential-box", classes.root)}>
        <Grid container className={clsx("grid-container")} spacing={2}>
          <Grid item className={clsx("grid-item")} xs={9}>
            <Title className={clsx("create-edit-credential")}>
              {isEditCredential === true ? "Edit " : "Create "} Credential
            </Title>
          </Grid>
        </Grid>
        <Formik
          className={clsx("formik-credential")}
          onSubmit={async (values) => {
            await createOrUpdateAccessCredential(values);
          }}
          enableReinitialize
          validateOnChange={false}
          initialValues={data}
          validationSchema={CredentialSchema}
        >
          {({ submitForm, values }) => (
            <Form
              className={clsx(
                "credential-form-container",
                classes.formContainer
              )}
            >
              {values.credentialType?.toLowerCase() !== "barcode" && (
                <InputField
                  component={TextField}
                  className={clsx("credential-reference", classes.flexInput)}
                  fieldName="credentialReference"
                  labelName="Credential Reference"
                />
              )}
              <Field
                test-id="credential-type"
                id="credential-type"
                className={clsx(
                  "credential-type",
                  classes.credentialType,
                  classes.flexInput
                )}
                component={TextField}
                name="credentialType"
                label="Type"
                select
                variant="outlined"
                SelectProps={{
                  SelectDisplayProps: {
                    "data-testid": "credential-type",
                  },
                }}
              >
                {credentialTypes?.map((type, index) => {
                  return (
                    isAllowedCredentialType(type) && (
                      <MenuItem
                        className={clsx(`menu-item-${type}`)}
                        key={index}
                        value={type}
                      >
                        {type}
                      </MenuItem>
                    )
                  );
                })}
              </Field>
              {values.credentialType?.toLowerCase() !== "barcode" && (
                <InputField
                  component={TextField}
                  className={clsx("credential-system-code", classes.flexInput)}
                  fieldName="systemCode"
                  labelName="System Code"
                />
              )}
              <Grid
                className={clsx("inner-grid-container", classes.btnContainer)}
                container
                spacing={2}
              >
                <Grid item className={clsx("inner-grid-item-submit")}>
                  <Button
                    className={clsx("credential-submit-btn")}
                    startIcon={<SaveIcon className={clsx("save-icon")} />}
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    {isEditCredential === true ? "Update" : "Create"}
                  </Button>
                </Grid>
                <Grid item className={clsx("inner-grid-item-cancel")}>
                  <Button
                    className={clsx("credential-cancel-btn")}
                    startIcon={<CancelIcon className={clsx("cancel-icon")} />}
                    variant="contained"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
};

AccessCredentialForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  onDelete: () => {},
  data: {
    credentialReference: "",
    credentialType: "",
    contractID: "",
    accessHolderID: "",
    systemCode: "",
    availableCodes: [],
  },
  inline: false,
};

AccessCredentialForm.propTypes = {
  data: PropTypes.shape({
    credentialID: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  inline: PropTypes.bool,
  onDelete: PropTypes.func,
  email: PropTypes.string,
  assignedAccessHolderName: PropTypes.shape({
    first: PropTypes.string.isRequired,
    last: PropTypes.string,
  }),
};

export default AccessCredentialForm;
