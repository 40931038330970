import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Field, Formik, useFormikContext } from "formik";
import { useStyles } from "./styles";
import { FormControlLabel, Checkbox } from "@material-ui/core";
import * as Yup from "yup";
import { generateUUID } from "../../../Rate";
import { isEqual } from "lodash";
import moment from "moment-timezone";
import { dayToValueConverter } from "../../../Panels/AccessGroups/utilities";
import { TimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import useCurrentFacilityTimezone from "../../../../hooks/useCurrentFacilityTimezone";

export const AccessTimeValidationSchema = Yup.object().shape({
  startTime: Yup.string()
    .required("Required")
    .nullable(),
  endTime: Yup.string()
    .required("Required")
    .test("is-greater", "End time cannot be before start time", function(
      value
    ) {
      if (!value) return false;
      let { startTime } = this.parent;
      startTime = moment(moment(startTime));
      const endTime = moment(value);
      const duration = moment.duration(endTime.diff(startTime));
      const isWithin24Hours = duration.asHours() <= 24;
      const isFuture = endTime.isAfter(startTime);
      return isWithin24Hours && isFuture;
    }),
  days: Yup.object().test(
    "days",
    "*Must select at least one option",
    (values) => {
      if (!values) return false;
      const isAnySet = Object.values(values)?.find((x) => x === true);
      return isAnySet >= 1;
    }
  ),
});

const getDayValueByDayName = (day) => {
  switch (day?.toLowerCase()) {
    case "monday":
      return 1;
    case "tuesday":
      return 2;
    case "wednesday":
      return 3;
    case "thursday":
      return 4;
    case "friday":
      return 5;
    case "saturday":
      return 6;
    case "sunday":
      return 7;
    default:
      return 0;
  }
};

const AccessGroupAccessTimesForm = ({
  data,
  onChange,
  validChanged,
  ...props
}) => {
  const formattedData = (data) => {
    const startTime =
      data.admittanceTimes?.length > 0
        ? data.admittanceTimes[0].startTime
        : new Date();
    const endTime =
      data.admittanceTimes?.length > 0
        ? data.admittanceTimes[0].endTime
        : new Date();

    let applicableDays = [];

    if (
      data.admittanceTimes?.some(
        (range) => typeof range.applicableDay === "number"
      )
    ) {
      applicableDays = [
        ...new Set(
          data.admittanceTimes?.map((range) => {
            return range.applicableDay;
          })
        ),
      ];
    } else {
      applicableDays = dayToValueConverter([
        ...new Set(
          data.admittanceTimes?.map((range) => {
            return range.applicableDay;
          })
        ),
      ]);
    }
    const fullWeek =
      applicableDays.length === 7 &&
      [1, 2, 3, 4, 5, 6, 7].every((day) => applicableDays.includes(day));
    const weekendsOnly =
      applicableDays.length === 2 &&
      [6, 7].every((day) => applicableDays.includes(day));

    const weekDaysOnly =
      applicableDays.length === 5 &&
      [1, 2, 3, 4, 5].every((day) => applicableDays.includes(day));

    return {
      accessGroupRuleID: data.accessGroupRuleID,
      identifier: data.identifier,
      startTime,
      endTime,
      days: {
        fullWeek,
        weekdays: weekDaysOnly,
        weekends: weekendsOnly,
        monday: applicableDays.includes(1),
        tuesday: applicableDays.includes(2),
        wednesday: applicableDays.includes(3),
        thursday: applicableDays.includes(4),
        friday: applicableDays.includes(5),
        saturday: applicableDays.includes(6),
        sunday: applicableDays.includes(7),
      },
    };
  };
  const { timeZone } = useCurrentFacilityTimezone();
  const handleSubmit = (values) => {
    let selectedDays = [];
    for (var key in values.days) {
      if (values.days[key] === true) selectedDays.push(key);
    }
    let dayVals = selectedDays.map((day) => getDayValueByDayName(day));
    dayVals = dayVals.filter((day) => day >= 1);
    let formattedReturn = {
      identifier: values.identifier,
      accessGroupRuleID: values.accessGroupRuleID,
      admittanceTimes: dayVals.map((day) => {
        return {
          startTime: moment(values.startTime).tz(timeZone, false).format("YYYY-MM-DDTHH:mm:ssZ"),
          endTime: moment(values.endTime).tz(timeZone, false).format("YYYY-MM-DDTHH:mm:ssZ"),
          applicableDay: day,
        };
      }),
    };
    onChange(formattedReturn);
  };

  return (
    <Formik
      className={clsx("access-time-form")}
      initialValues={{
        ...formattedData(data),
      }}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validationSchema={AccessTimeValidationSchema}
    >
      <Content
        className={clsx("access-time-content")}
        validChanged={validChanged}
        {...props}
      />
    </Formik>
  );
};

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

const weekEnds = ["Saturday", "Sunday"];

const Content = React.memo(
  ({ validChanged }) => {
    const classes = useStyles();
    const {
      errors,
      setFieldValue,
      submitForm,
      values,
      validateForm,
      isValid,
    } = useFormikContext();
    const valRef = useRef(values);    

    const { timeZone } = useCurrentFacilityTimezone();
    useEffect(() => {
      validChanged(isValid);
    }, [isValid]);

    useEffect(() => {
      if (!isEqual(values, valRef.current)) {
        if (!values.identifier) {
          values.identifier = generateUUID();
          setFieldValue("identifier", values.identifier);
        }
        validateForm(values);
        submitForm(values);
        valRef.current = values;
      }
    }, [values]);

    const toggleCheckboxGroup = async (checked, current, setter, values) => {
      setter(`days.${current}`, checked);
      const keys = Object.keys(values);
      for (var val of keys) {
        if (values[val] === true && checked === true)
          setter(`days.${val}`, false);
      }

      switch (current) {
        case "fullWeek":
          [...weekDays, ...weekEnds].forEach((val) =>
            setter(`days.${val.toLowerCase()}`, checked)
          );
          break;
        case "weekends":
          weekDays.forEach((val) => setter(`days.${val.toLowerCase()}`, false));
          weekEnds.forEach((val) =>
            setter(`days.${val.toLowerCase()}`, checked)
          );
          break;
        case "weekdays":
          weekDays.forEach((val) =>
            setter(`days.${val.toLowerCase()}`, checked)
          );
          weekEnds.forEach((val) => setter(`days.${val.toLowerCase()}`, false));
          break;
        default:
          return;
      }
    };

    return (
      <div className={clsx("access-times", classes.root)}>
        <div
          className={clsx("date-range-container", classes.dateRangeContainer)}
        >
          <div className={clsx("date-spans", classes.dateSpans)}>
            <div>
              <Field
                className={clsx("start-time")}
                name="startTime"
                component={({ field }) => (
                  <TimePicker
                    className={clsx("start-time-picker")}
                    name="startTime"
                    label="Start Time"
                    value={field.value ? moment(field.value).tz(timeZone, false) : null}
                    onChange={(val) => {
                      setFieldValue("startTime", val);
                    }}
                  />
                )}
              />
              <p className={clsx("start-time-errors", classes.error)}>
                {errors.startTime}
              </p>
            </div>
            <div>
              <Field
                className={clsx("end-time")}
                name="endTime"
                component={({ field }) => (
                  <TimePicker
                    className={clsx("end-time-picker")}
                    name="endTime"
                    label="End Time"
                    value={field.value ? moment(field.value).tz(timeZone, false) : null}
                    onChange={(val) => {
                      setFieldValue("endTime", val);
                    }}
                  />
                )}
              />
              <p className={clsx("end-time-errors", classes.error)}>
                {errors.endTime}
              </p>
            </div>
          </div>
        </div>
        <p className={clsx("days-errors", classes.error)}>{errors.days}</p>
        <div className={clsx("days-container", classes.daysContainer)}>
          <FormControlLabel
            className={clsx("form-control-full-week")}
            control={<Checkbox className={clsx("checkbox-full-week")} />}
            type="checkbox"
            label="Full Week"
            checked={values.days.fullWeek}
            onChange={() =>
              toggleCheckboxGroup(
                !values.days.fullWeek,
                "fullWeek",
                setFieldValue,
                {
                  weekends: values.days.weekends,
                  weekdays: values.days.weekdays,
                }
              )
            }
          />
          <FormControlLabel
            className={clsx("form-control-weekdays")}
            control={<Checkbox className={clsx("checkbox-weekdays")} />}
            type="checkbox"
            label="Weekdays Only"
            checked={values.days.weekdays}
            onChange={() =>
              toggleCheckboxGroup(
                !values.days.weekdays,
                "weekdays",
                setFieldValue,
                {
                  fullWeek: values.days.fullWeek,
                  weekends: values.days.weekends,
                }
              )
            }
          />
          <FormControlLabel
            className={clsx("form-control-weekends")}
            control={<Checkbox className={clsx("checkbox-weekends")} />}
            type="checkbox"
            label="Weekends Only"
            checked={values.days.weekends}
            onChange={() =>
              toggleCheckboxGroup(
                !values.days.weekends,
                "weekends",
                setFieldValue,
                {
                  weekdays: values.days.weekdays,
                  fullWeek: values.days.fullWeek,
                }
              )
            }
          />
        </div>
        <div
          className={clsx(
            "days-of-week-container",
            classes.daysOfWeekContainer
          )}
        >
          {[...weekDays, ...weekEnds].map((day, index) => (
            <FormControlLabel
              className={clsx(`form-control-${day}`)}
              key={index}
              control={<Checkbox className={clsx(`checkbox-${day}`)} />}
              label={day}
              disabled={
                values.days.fullWeek ||
                values.days.weekends ||
                values.days.weekdays
              }
              checked={values.days[day.toLowerCase()]}
              onChange={() => {
                setFieldValue(
                  `days.${day.toLowerCase()}`,
                  !values.days[day.toLowerCase()]
                );
              }}
            />
          ))}
        </div>
      </div>
    );
  },
  (prev, curr) => {
    return JSON.stringify(prev) === JSON.stringify(curr);
  }
);

Content.displayName = "Content";

AccessGroupAccessTimesForm.defaultProps = {
  data: {
    startTime: new Date(),
    endTime: new Date(),
  },
  onChange: () => {},
  validChanged: () => {},
};

AccessGroupAccessTimesForm.propTypes = {
  data: PropTypes.shape({
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired,
  }),
  onChange: PropTypes.func,
  validChanged: PropTypes.func,
};

export default AccessGroupAccessTimesForm;
