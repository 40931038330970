import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  autoComplete: {
    minWidth: "20rem",
  },

  progressIndicator: {
    color: "#fafafa",
  },
  listItemDiv: {
    width: "100%",
    overflow: "auto",
  },
  flexibleRatesContainer: {
    margin: theme.spacing(1),
  },
  denyEntryContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  maxFeeContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  endOfDayContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chargeFrequencyContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  chargeFrequencyDropdown: {
    marginTop: theme.spacing(1),
  },
  description: {
    marginBottom: theme.spacing(1)
  },
  ratewithautocomplete: {
    minWidth:"100% !Important"
  },
}));
