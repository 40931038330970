import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  daysContainer: {},
  error: { color: theme.palette.error?.main },
  dateRangeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(1),
    flex: "1",
    flexWrap: "wrap",
  },
  monthPicker: {
    flexBasis: "100%",
    marginBottom: theme.spacing(2),
  },
  dateSpans: {
    flexBasis: "100%",
    display: "inline-flex",
    "& div": {
      marginRight: theme.spacing(2),
    },
  },
}));
