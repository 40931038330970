import React from "react";
import Container from "@material-ui/core/Container";
import Copyright from "./Copyright";
import { useStyles } from "./styles";


const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container>
        <Copyright />
      </Container>
    </footer>
  );
};

export default Footer;
