import React from "react";
import { PropTypes } from "prop-types";
import { FastField, Field } from "formik";
import { OutlinedInput, InputLabel, FormControl } from "@material-ui/core";
import { useStyles } from "./style";
import clsx from "clsx";
import InputMask from "react-input-mask";

const InputField = ({ className, fieldName, labelName, mask, disabled }) => {
  const classes = useStyles();

  return (
    <FastField name={fieldName}>
      {({ field, form }) => (
        <FormControl
          className={clsx(
            className,
            form.errors[fieldName] && form.touched[fieldName]
              ? classes.error
              : ""
          )}
          variant="outlined"
        >
          <InputLabel htmlFor={fieldName}>{labelName}</InputLabel>
          {mask ? (
            <InputMask {...field} id={fieldName} mask={mask}>
              {(innerProps) => (
                <OutlinedInput
                  {...innerProps}
                  label={labelName}
                  name={fieldName}
                  type="text"
                  id={fieldName}
                  disabled={disabled}
                />
              )}
            </InputMask>
          ) : (
            <OutlinedInput
              {...field}
              id={fieldName}
              type="text"
              label={labelName}
              disabled={disabled}
            />
          )}

          {form.errors[fieldName] && form.touched[fieldName] ? (
            <div id="error-label" data-testid={`errors-${fieldName}`} data-id={fieldName}>
              {form.errors[fieldName]}
            </div>
          ) : null}
        </FormControl>
      )}
    </FastField>
  );
};

InputField.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string,
  labelName: PropTypes.string,
  mask: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputField;
