import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { useStyles } from "./style";
import * as Yup from "yup";
import clsx from "clsx";
import { useFormContext, Controller } from "react-hook-form";

export const EmailSubformSchema = Yup.object().shape({
    isRequired: Yup.boolean(),
    type: Yup.string()
        .oneOf(["Unset", "Personal", "Work"])
        .default("Personal")
        .notRequired(),
    emailAddress: Yup.string().trim('No leading or trailing whitespaces...')
        .email()
        .when("isRequired", {
            is: (value) => value === true,
            then: Yup.string()
                .email("Must be a valid email")
                .required("* Required"),
            otherwise: Yup.string()
                .email("Must be a valid email")
                .notRequired(),
        }),
});

const EmailSubform = ({
    index,
    disabled,
}) => {
    const classes = useStyles();
    const { control, setValue, trigger, formState: { errors } } = useFormContext();

    const formatTextField = (e) => {
        const val = (e.target.value || "").replace(/\s+/gi, " ");
        setValue(e.target.name, val.trim());
        trigger(e.target.name);
    };

    const getError = () => {
        if (errors.contactInfo?.emails && errors.contactInfo?.emails[index]?.emailAddress) {
            return errors.contactInfo?.emails[index]?.emailAddress.message.replace(`contactInfo.emails[${index}].emailAddress m`, 'M');
        }
    }

    return (
        <Controller
            name={`contactInfo.emails.${index}.emailAddress`}
            control={control}
            render={({ field }) => <TextField
                {...field}
                size="small"
                className={clsx("email-address", classes.flexInput)}
                label="Email"
                variant="outlined"
                onBlur={formatTextField}
                error={errors.contactInfo?.emails && errors.contactInfo?.emails[index]?.emailAddress ? true : false}
                helperText={getError()}
                InputProps={{
                    readOnly: Boolean(disabled),
                    "aria-readonly": Boolean(disabled),
                    disabled: Boolean(disabled),
                }}
            />
            }
        />
    );
};

EmailSubform.defaultProps = {
    disabled: false,
};

EmailSubform.propTypes = {
    index: PropTypes.number,
    disabled: PropTypes.bool,
};

export default EmailSubform;
