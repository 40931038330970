import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Palette } from "@material-ui/icons";

export const useStyles = makeStyles((theme) => ({
	root: {},
	flag: {
		marginLeft: "20px",
		color: theme.palette.text.primary
	},
}));