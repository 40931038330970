import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    margin: theme.spacing(1),
  },
  btnContainer: {
    marginLeft: "0px",
    marginTop: "5px",
  },
  flexInput: { margin: theme.spacing(1), flexGrow: "1" },
  credentialType: { flexBasis: "50%" },
  selectedType: {
    margin: "0 1rem 0 0",
    display: "flex",
    alignItems: "center",
    minWidth: "70px"
  },
  inlineRoot: {
    display: "inline-flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
    "& .credentialType": {
      maxWidth: "200px",
      [theme.breakpoints.down("sm")]: {
        width: "150px",
      },
    },
    "& .generateBarcodeBtn": {
      margin: theme.spacing(2),
      maxHeight: "35px",
    },
    "& .cancelSelectionsBtn": {
      margin: theme.spacing(2),
      marginRight: theme.spacing(14),
      maxHeight: "35px",
    },
    "& .deleteBtnWithoutRightSpace": {
      margin: theme.spacing(2),
      maxHeight: "35px",
    },
    "& .deleteBtnWithRightSpace": {
      margin: theme.spacing(2),
      marginRight: theme.spacing(14),
      maxHeight: "35px",
    },
    "& .email-btn": {
      margin: theme.spacing(2),
      maxHeight: "35px",
    },
    "& .saveCredentialBtn": {
      margin: theme.spacing(2),
      maxHeight: "35px",
    }
  },
  flexDropdown: {
    flexBasis: "200px",
    margin: "8px"
  },
}));
