import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Grid, MenuItem, Typography, InputAdornment } from "@material-ui/core";
import { useStyles } from "./style";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import ColoredLine from "../../../ColoredLine";
import { TimePicker } from "@material-ui/pickers";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import * as c from "../../../../constants";

export default function FPASettingsSubform({
  control,
  errors,
  currentChargeFrequency,
  availableRates,
  currentRate,
  denyEntry,
  handleRateChange,
  currentType
}) {
  const chargeFrequencies = [
    {
      name: "Unlimited",
      value: c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.Unlimited,
    },
    {
      name: "Charge Once Per Day",
      value: c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeOncePerDay,
    },
    {
      name: "Charge Once Per 24 Hours",
      value: c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeOncePer24Hours,
    },
    {
      name: "Charge Until Daily Max",
      value: c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntilDailyMax,
    },
    {
      name: "Charge Until 24 Hour Max",
      value: c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntil24HourMax,
    },
  ];

  const classes = useStyles();

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => option.name,
    trim: true,
    ignoreCase: true,
  });

  const [selectedRate, setSelectedRate] = useState(null);

  useEffect(() => {
    if (availableRates.length > 0) {
      const matchingRate = availableRates
        .filter((x) => x.rateBlobID === currentRate)
        .at(0);
      setSelectedRate(matchingRate ?? null);
    }
  }, [availableRates, currentRate]);

  const handleSelectedRateChange = (rate) => {
    if (rate !== null) {
      setSelectedRate(rate);
      handleRateChange(rate.rateBlobID);
    }
  };

  return (
      <Grid className={clsx(classes.flexibleRatesContainer)}>
        {currentType === c.ACCESS_GROUP_TYPE["Issue Ticket"] && (
          <Grid  className={clsx(
            "issue-ticket-description",
            classes.description
          )}>
          <Typography variant="caption">
          With this access group type, when an access holder presents their
          credential, a ticket will be issued with the selected rate. Issue Ticket
          access groups are for use at ticket dispensers only. Configure the access
          Readers section of the access group accordingly.
          </Typography>
          </Grid>
        )}
        {currentType === c.ACCESS_GROUP_TYPE["Day Use"] && (
        <Grid className={clsx(
          "day-use-description",
          classes.description
        )}>
          <Typography variant="caption">
            This access group type deducts one day of parking per 24 hours after the initial entry.
            Unlimited in/out uses are allowed during the 24 hour period.
          </Typography>
        </Grid>
      )}
        <Grid item xs={12}>
          <Autocomplete
            data-testid="accessgroup-rate-search"
            className={clsx(["app-search", classes.autoComplete, classes.ratewithautocomplete])}
            options={availableRates ?? []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option) => option.rateBlobID === currentRate}
            onChange={(e, data) => handleSelectedRateChange(data)}
            value={selectedRate}
            name="fpaSettings.rateBlobID"
            hidden={currentType === c.ACCESS_GROUP_TYPE["Shared Accounts"]}
            renderInput={(params) => (
              <TextField
                label={currentRate ? "Rate" : currentType === c.ACCESS_GROUP_TYPE["Day Use"]? "Over Stay Rates" : "Search Rates..."}
                fullWidth
                {...params}
                error={!!errors?.fpaSettings?.rateBlobID}
                helperText={!!errors?.fpaSettings?.rateBlobID && errors.fpaSettings?.rateBlobID.message}
                id="accessgroup-flexible-rate"
                className={clsx("accessgroup-flexible-rate")}
                variant="outlined"
              />
            )}
            filterOptions={filterOptions}
            renderOption={(option) => (
              <div
                onClick={() => handleSelectedRateChange(option)}
                className={clsx([classes.listItemDiv])}
              >
                <span data-id={option.rateID} data-value={option.rateBlobID}>
                  {option.name}
                </span>
              </div>
            )}
          />
        </Grid>

        {currentType !== c.ACCESS_GROUP_TYPE["Issue Ticket"] && currentType !== c.ACCESS_GROUP_TYPE["Day Use"] && (
          <Grid item xs={12} className={clsx(classes.denyEntryContainer)}>
            <label
              className={clsx("deny-entry-checkbox-label", classes.checkboxLabel)}
            >
              <Controller
                control={control}
                name="fpaSettings.denyEntry"
                render={({ field }) => (
                  <input
                    {...field}
                    className={clsx("deny-entry-checkbox", classes.checkbox)}
                    type="checkbox"
                    data-testid="deny-entry-checkbox"
                    checked={field.value}
                    data-checked={field.value}
                  />
                )}
              />
              Deny entry when account has less than daily/24 hour max
            </label>
          </Grid>
        )}

        {(currentChargeFrequency ===
          c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntil24HourMax ||
          currentChargeFrequency ===
            c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntilDailyMax || denyEntry) && (
          <Grid item xs={12} className={clsx(classes.maxFeeContainer)}>
            <Controller
              name="fpaSettings.maxFee"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Daily/24 Hour Max"
                  fullWidth
                  {...field}
                  error={!!errors?.fpaSettings?.maxFee}
                  helperText={
                    !!errors?.fpaSettings?.maxFee &&
                    errors?.fpaSettings?.maxFee.message
                  }
                  type="text"
                  variant="outlined"
                  className={clsx("maxFee")}
                  InputProps={{
                    inputProps: {
                      "data-testid": "accessgroup-max-fee",
                      "aria-label": "maxFeeTextField",
                    },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        )}

        {currentType !== c.ACCESS_GROUP_TYPE["Day Use"] && ( 
        <ColoredLine/>
        )}

        {(currentType === c.ACCESS_GROUP_TYPE["Flexible Parking Account"]) && (
          <Grid item xs={12} className={clsx(classes.chargeFrequencyContainer)}>
            <Typography className={clsx("charge-frequency")} variant="h5">
              Charge Frequency Restrictions
            </Typography>
            <Controller
              defaultValue={c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.Unlimited}
              name="fpaSettings.fpaFrequencyID"
              control={control}
              render={({ field }) => (
                <TextField
                  label="Charge Frequency"
                  fullWidth
                  {...field}
                  error={!!errors.fpaSettings?.fpaFrequencyID}
                  helperText={errors.fpaSettings?.fpaFrequencyID && "Required"}
                  id="charge-frequency"
                  className={clsx(
                    "charge-frequency",
                    classes.chargeFrequencyDropdown
                  )}
                  select
                  variant="outlined"
                  SelectProps={{
                    SelectDisplayProps: {
                      "data-testid": "charge-frequency",
                    },
                  }}
                >
                  {chargeFrequencies.map((frequency) => (
                    <MenuItem key={frequency.value} value={frequency.value}>
                      {frequency.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            {(currentChargeFrequency ===
              c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeOncePerDay ||
              currentChargeFrequency ===
                c.FLEXIBLE_PARKING_ACCOUNT_FREQUENCY.ChargeUntilDailyMax) && (
              <Grid item xs={12} className={clsx(classes.endOfDayContainer)}>
                <Controller
                  control={control}
                  name="fpaSettings.endOfDay"
                  render={({ field }) => (
                    <TimePicker
                      label="End of Day"
                      fullWidth
                      {...field}
                      error={!!errors.fpaSettings?.endOfDay}
                      helperText={errors.fpaSettings?.endOfDay && "Required"}
                      className={clsx("endOfDayTimePicker")}
                      inputVariant="outlined"
                      ref={null}
                      data-testid="accessgroup-end-of-day"
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
  );
}
