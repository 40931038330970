import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    nameContainer: {
        display: "inline-flex",
        [theme.breakpoints.down("sm")]: {
            display: "block",
        },
    },
    firstName: {
        margin: theme.spacing(1),
        flex: 1,
    },
    lastName: {
        margin: theme.spacing(1),
        flex: 1,
    },
    addressContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    flexInput: { margin: theme.spacing(1) },
    phoneEmailContainer: {
        display: "inline-flex",
    },
    emailContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
    phoneContainer: {
        display: "flex",
        flexWrap: "wrap",
    },
}));
