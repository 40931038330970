import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import DeviceService from "../../../../services/DeviceService";
import apiClient from "../../../../auth/apiClient";
import { useEnqueueSnackbar } from "../../../../hooks/useEnqueueSnackbar";
import { useStyles } from "./styles";
import { Form, useFormik } from "formik";
import * as Yup from "yup";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import SearchBar from "../../../SearchBar";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button } from "@material-ui/core";
import useCurrentFacility from "../../../../hooks/useCurrentFacility";

const deviceService = new DeviceService(apiClient);

export const AccessReaderSchema = Yup.object().shape({});

const AccessReaderForm = ({ className, data, onChange }) => {
  const classes = useStyles();
  const { facilityID } = useCurrentFacility();
  const scopeAwareFacilityID = useSelector((state) => state.entityScope?.facilityGroupId || facilityID);
  const enqueueSnackbar = useEnqueueSnackbar();
  const [deviceGroups, setDeviceGroups] = useState();
  const [filteredGroups, setFilteredGroups] = useState();
  const [expansions, setExpansions] = useState([]);
  const formik = useFormik({
    initialValues: {},
    validationSchema: AccessReaderSchema,
  });

  useEffect(() => {
    fetchDeviceGroups(scopeAwareFacilityID);
    }, [scopeAwareFacilityID]);

  useEffect(() => {
    setFilteredGroups(deviceGroups);
  }, [deviceGroups]);

  const fetchDeviceGroups = useCallback(
    async (scopeAwareFacilityID) => {
      let response;
      try {
        response = await deviceService.getGroupedDevices(scopeAwareFacilityID);
      } catch {
        enqueueSnackbar("Failed to retrieve device groups", {
          variant: "error", tag: "FailedToRetrieveDeviceGroups"
        });
        return;
      }

      setDeviceGroups(response.data);
      let mutatedKeyData = {};
      data.forEach((device) => (mutatedKeyData[device] = true));
      let tmp = {};
      let expansionGroups = {};
      let allDevicesChecked = true;
      response.data.forEach((group) => {
        expansionGroups[group.entityID] = false;
        let wholeGroupChecked = true;
        group.parkingDevices.map((device) => {
          let deviceChecked = mutatedKeyData[device.deviceID];
          if (deviceChecked === undefined) {
            wholeGroupChecked = false;
            allDevicesChecked = false;
          } else device.checked = deviceChecked;

          return device;
        });
        tmp[group.entityID] = {
          checked: wholeGroupChecked,
          devices: group.parkingDevices,
        };
      });
      tmp.allDevices = allDevicesChecked;
      formik.setValues(tmp);
      setExpansions(expansionGroups);
    },
    [scopeAwareFacilityID]
  );

  const handleSearchChange = useCallback(
    (val) => {
      const filtered = [];
      if (!deviceGroups) return;
      deviceGroups.forEach((x) => {
        let tmp = { ...x };
        tmp.parkingDevices = [];
        x.parkingDevices.forEach((c) => {
          if (c.name.toLowerCase().includes(val.toLowerCase()))
            tmp.parkingDevices.push(c);
        });
        if (tmp.parkingDevices.length > 0) filtered.push(tmp);
      });
      setFilteredGroups(filtered);
    },
    [deviceGroups]
  );

  const toggleAllChildren = () => {
    const { values, setValues } = formik;
    const checked = !values.allDevices; 
    Object.keys(values).forEach((key) => {
      if (!values[key].devices) return;
        values[key].checked = checked;
        values[key].devices.map((device) => (device.checked = checked));
        });

    let tmpExpansions = { ...expansions };
    Object.keys(tmpExpansions ?? []).forEach(
      (val) => (tmpExpansions[val] = checked)
    );

    setExpansions(tmpExpansions);
    values.allDevices = checked;
    setValues(values);
    handleSubmit();
  };

  const isGroupChecked = useCallback(
    (id) => {
      const { values } = formik;
      return values[id]?.checked ?? false;
    },
    [formik.values]
  );

  const isDeviceChecked = useCallback(
    (entityID, deviceID) => {
      const { values } = formik;
      if (!values[entityID]) return false;
      const found = values[entityID].devices.find(
        (x) => x.deviceID === deviceID
      );
      return found.checked ?? false;
    },
    [formik.values]
  );

  const toggleGroup = (entityID) => {
    const { values, setValues } = formik;
    values[entityID].devices.forEach(
      (device) => (device.checked = !values[entityID].checked)
    );
    values[entityID].checked = !values[entityID].checked;
    values.allDevices = false;
    setValues(values);
  };

  const toggleDevice = (entityID, deviceID) => {
    const { values, setValues } = formik;
    const index = values[entityID].devices.findIndex(
      (x) => x.deviceID === deviceID
    );
    if (index < 0) return;
    const checked = values[entityID].devices[index].checked ?? false;
    values[entityID].devices[index].checked = !checked;
    if (!checked === false) values[entityID].checked = false;
    values.allDevices = false;
    setValues(values);
  };

  const handleSubmit = () => {
    let values = [];
    Object.values(formik.values).forEach((val) => {
      if (!val.devices) return;
      val.devices
        .filter((x) => x.checked === true)
        .forEach((device) => values.push(device.deviceID));
    });
    onChange(values);
  };

  return (
    <div
      className={clsx("access-readers", className, classes.root)}
      onChange={handleSubmit}
    >
      <SearchBar
        className={clsx("search-bar")}
        placeholder="Search Readers..."
        onChange={handleSearchChange}
        delay={0}
      />

      <Button 
        style={{float:"right"}}
        className={clsx(["btn-select-all-readers"])}
        size="small" 
        variant="outlined" 
        color="primary" 
        onClick={toggleAllChildren}
        >
          {(formik.values?.allDevices) 
            ? (<>Clear All Readers</>)
            : (<>Select All Readers</>)
          }
      </Button>
      <div style={{clear:"both", marginBottom:8}}></div>
      <div className={clsx("group-block", classes.groupBlock)}>
        {filteredGroups?.map((group, index) => {
          return (
            <Accordion
              className={clsx([classes.accordion,"accordion"])}
              key={index}
              expanded={expansions[group.entityID] ?? false}
            >
              <AccordionSummary
                className={clsx("accordion-summary")}
                expandIcon={
                  <IconButton
                    className={clsx("icon-button")}
                    onClick={() =>
                      setExpansions((prev) => ({
                        ...prev,
                        [group.entityID]: !expansions[group.entityID] ?? false,
                      }))
                    }
                  >
                    <ArrowDropDownIcon
                      className={clsx("arrow-dropdown-icon")}
                    />
                  </IconButton>
                }
              >
                <FormControlLabel
                  className={clsx("inner-form-control-label")}
                  control={<Checkbox className={clsx("inner-checkbox")} />}
                  type="checkbox"
                  label={group.entityName}
                  name={group.entityID}
                  checked={isGroupChecked(group.entityID)}
                  onChange={() => toggleGroup(group.entityID)}
                  onClick={(e) => e.stopPropagation()}
                />
              </AccordionSummary>
              <AccordionDetails className={clsx("accordion-details")}>
                <div className={clsx("devices-block", classes.devicesBlock)}>
                  {group.parkingDevices?.map((device, index) => (
                    <FormControlLabel
                      className={clsx(`form-control-label-${device.name}`)}
                      key={index}
                      control={
                        <Checkbox className={clsx(`checkbox-${index}`)} />
                      }
                      type="checkbox"
                      label={device.name}
                      name={device.deviceID}
                      checked={isDeviceChecked(group.entityID, device.deviceID)}
                      onChange={() =>
                        toggleDevice(group.entityID, device.deviceID)
                      }
                    />
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

AccessReaderForm.defaultProps = {
  data: [],
  onChange: () => {},
};

export default AccessReaderForm;
