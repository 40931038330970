import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useForm, Controller } from "react-hook-form";
import { useStyles } from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required")
});

const ForgotPasswordForm = ({ onCancel, onSubmit, cognitoErrors }) => {
  const classes = useStyles();
  const { handleSubmit, control, formState: { errors, isSubmitting } } = useForm({
    resolver: yupResolver(forgotPasswordSchema)
  });
  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit(async (values) => await onSubmit(values))}
      noValidate
    >
      <Controller
        name="email"
        control={control}
        render={({ field }) => <TextField
          {...field}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="email"
          id="email"
          label="Email Address"
          autoComplete="off"
          autoFocus
          error={!!errors.email || cognitoErrors?.email}
          helperText={(errors.email && errors.email.message) || (cognitoErrors?.email && cognitoErrors.email.message)}
        />}
      />

      <div className={classes.submitWrapper}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          disabled={isSubmitting}
        >
          Send Verification Code
        </Button>
        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
      <div className={classes.submitWrapper}>
        <Button
          fullWidth
          variant="contained"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default ForgotPasswordForm;
