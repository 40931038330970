import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@material-ui/core";
import { useFormik } from "formik";
import { useStyles } from "./style";
import * as Yup from "yup";
import MultiValidationOfferDropdown from "../../Dropdowns/MultiValidationOfferDropdown";
import clsx from "clsx";
import { useEnqueueSnackbar } from "../../../hooks/useEnqueueSnackbar";
import CredentialService from "../../../services/CredentialService";
import apiClient from "../../../auth/apiClient";
import moment from "moment";
import TicketService from "../../../services/TicketService";
import {useSelector} from "react-redux";

const credentialService = new CredentialService(apiClient);
const ticketService = new TicketService(apiClient);

const ApplyValidationSchema = Yup.object().shape({
  validationOffer: Yup.number().required("Must select a validation"),
});

export default function ApplyValidationForm({
  onSubmit,
  contractHolderIDs,
  ticketID,
  deviceID,
  validationOffers
}) {
  const classes = useStyles();
  const enqueueSnackbar = useEnqueueSnackbar();
  const [selectedValidation, setSelectedValidation] = useState(null);
  const currentUserID = useSelector((state) => state.user?.UserID);

  const { submitForm, dirty, errors, setFieldValue, isSubmitting } = useFormik({
    initialValues: {},
    onSubmit: handleValidationOfferSubmit,
    validationSchema: ApplyValidationSchema,
  });

  const offerChange = (offer) => {
    setFieldValue("validationOffer", offer?.id);
    setSelectedValidation(offer);
  };

  async function handleValidationOfferSubmit(values) {
    const { validationOffer } = values;
    if (!ticketID) {
      enqueueSnackbar("There is no ticket to associate this validation with", {
        variant: "warning", tag: "NoTicketForValidation"
      });
      return;
    }
    try {
      const ticket = await ticketService.retrieveTicketById(ticketID);
      if (!ticket.data.parkingTransactionID) {
        console.warn("No parking transaction associated with given ticket");
        enqueueSnackbar(
          "Could not find a parking transaction for the given ticket",
          { variant: "warning", tag: "NoTransactionForTicket" }
        );
        return;
      }

      const bulkValidationResponse = await credentialService.BulkCreateValidations(
        {
          validationOffer,
          quantity: 1,
          validFrom: moment.min().format("YYYY-MM-DDTHH:mm:ssZ"),
          validUntil: moment.max().format("YYYY-MM-DDTHH:mm:ssZ"),
          maxUses: 1,
          maxUsesPerTransaction: 1,
          kind: "Online",
          format: "QR Code",
          userID: currentUserID,
        }
      );

      const validation = bulkValidationResponse.data[0];
      if (!validation) {
        console.warn("No validation returned from bulk response");
        enqueueSnackbar("Invalid validation returned from offer creation", {
          variant: "warning", tag: "InvalidValidationReturned"
        });
      }

      await credentialService.applyOnlineValidation(
        validation.id,
        ticket.data.parkingTransactionID
      );

      enqueueSnackbar("Successfully created validation", {
        variant: "success",
      });
      validation.name = selectedValidation?.name;
      validation.rateBlobID = selectedValidation?.rate;
      onSubmit(validation);
    } catch (err) {
      console.error(err);
      enqueueSnackbar("Failed to apply validation to ticket", {
        variant: "error", tag: "FailedToApplyValidationToTicket"
      });
    }
  }

  return (
    <div className={classes.root}>
      <MultiValidationOfferDropdown
        className={classes.offerDropdown}
        contractHolderIDs={contractHolderIDs}
        errors={errors}
        onChange={offerChange}
        deviceID={deviceID}
        validationOffers={validationOffers}
      />
      <div className={classes.btnGroup}>
        {isSubmitting && (
          <CircularProgress
            size={35}
            className={clsx("loading-spinner", classes.loadingSpinner)}
          />
        )}
        <Button
          className={clsx("submit-validation-button", classes.submitBtn)}
          color="primary"
          variant="contained"
          onClick={submitForm}
          disabled={!dirty || isSubmitting}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

ApplyValidationForm.defaultProps = {
  onSubmit: () => {},
};

ApplyValidationForm.propTypes = {
  onSubmit: PropTypes.func,
  contractHolderIDs: PropTypes.arrayOf(PropTypes.string).isRequired,
  ticketID: PropTypes.string.isRequired,
  deviceID: PropTypes.string
};
